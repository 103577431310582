import React, { useContext } from "react";
import { UseContext } from "../context";
import { Navigate, useNavigate } from "react-router-dom";
import leftarrow from "../image/leftarrow.svg";
import okimg from "../image/okimg.svg";
import timeimg from "../image/back-in-time(1) 1.svg";
import Loadingcom from "./allcomponent/Loading";
const Historycom = () => {
  const {
    alltravel,
    Showhistoryoftr,
    showexit,
    showtrinprogress,
    setShowtrinprogress,
    showhis,
    setShowhis,
    shownav,
    setShowhistrinprogress,
    getprofloading,
    getmyprof,
    setShownav,
  } = useContext(UseContext);
  const navigate = useNavigate();
  const handleshowhistoryoftr = (item) => {
    setShownav(false);
    navigate("/history/travelhistory", { state: item });
    setShowhistrinprogress(false);
  };
  const handleshowhistroyprogress = (item) => {
    setShownav(false);
    navigate("/history/travelhistory", { state: item });
    setShowhistrinprogress(true);
  };
  const handleshowhis = () => {
    setShowhistrinprogress(false);
    setShowhis(true);
    setShowtrinprogress(false);
  };
  const handleshowtravelinprogress = () => {
    setShowhistrinprogress(true);
    setShowtrinprogress(true);
    setShowhis(false);
  };
  const groupedByDate = alltravel.reduce((all, item) => {
    const date = item.date;
    if (!all[date]) {
      all[date] = [];
    }
    all[date].push(item);
    return all;
  }, {});
  const groupedArray = Object.entries(groupedByDate);
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {getprofloading === false ? (
            <>
              {getmyprof.status === "profile_not_completed" ? (
                <Navigate to="/UserandCarinformation" />
              ) : (
                <>
                  {getmyprof.status === "pending" ? (
                    <div className="w-full flex flex-col h-full  overflow-auto mb-[100px] lg:mb-[88px]">
                      <div className=" h-full w-full flex flex-col   items-center justify-center ">
                        <>
                          <div
                            id="traveldiv"
                            className=" flex  items-center justify-center  "
                          >
                            <p className="text-[20px] text-center  text-[black] font-bold">
                              حساب کاربری شما هنوز تایید نشده است!
                            </p>
                          </div>
                          <div className="mt-4">
                            <Loadingcom />
                          </div>
                        </>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="historydiv"
                      className="w-full h-full overflow-auto mb-[160px]"
                    >
                      {Showhistoryoftr === false && shownav === true && (
                        <>
                          {" "}
                          <div className="grid grid-cols-2 text-[16px] font-medium mt-6 ">
                            <div className=" flex-row">
                              <div
                                className="flex justify-center cursor-pointer"
                                onClick={handleshowhis}
                              >
                                <p>تاریخچه سفر</p>
                              </div>
                              {showhis && (
                                <>
                                  <div className="h-[4px] w-[100%] bg-black rounded-[3px] mt-4"></div>
                                </>
                              )}
                            </div>
                            <div className="flex-row">
                              <div
                                className="flex justify-center cursor-pointer"
                                onClick={handleshowtravelinprogress}
                              >
                                <p>سفر های در جریان</p>
                              </div>
                              {showtrinprogress && (
                                <>
                                  <div className="h-[4px] w-[100%] bg-black rounded-[3px] mt-4"></div>
                                </>
                              )}
                            </div>
                          </div>
                          {/* <div className="space-y-3">
                            {showhis === true && showtrinprogress === false && (
                              <>
                                {" "}
                                {groupedArray.map(([date, items]) => (
                                  <div>
                                    <p className="text-[12px] font-medium  text-center pt-2">
                                      {date}
                                    </p>{" "}
                                    {items.map((item, idx) => (
                                      <div
                                        className={`h-[115px] cursor-pointer shadow-navboxshadow flex-row bg-historydivc rounded-[10px] mx-4 border-lightline mt-4 border-[1px] ${
                                          idx === 0 ? "mt-2" : ""
                                        }`}
                                        onClick={
                                          showexit === false
                                            ? () => handleshowhistoryoftr(item)
                                            : null
                                        }
                                      >
                                        <p className="text-[12px] font-normal text-desc p-4">
                                          {item.des
                                            .split(" ")
                                            .slice(0, 10)
                                            .join(" ")}
                                          {item.des.split(" ").length > 10
                                            ? "..."
                                            : ""}
                                        </p>
                                        <div className="border-[1px] h-[1px] border-lightline mx-4 mt-1"></div>
                                        <div className="flex gap-4 mx-4 mt-4">
                                          <div className="w-[30%] h-[26px] bg-okdivc rounded-[7px] flex items-center ">
                                            <img
                                              alt=""
                                              className="w-[14px] h-[10px] mr-2 "
                                              src={okimg}
                                            />
                                            <p className="text-[12px] font-medium text-center w-full">
                                              انجام شد
                                            </p>
                                          </div>
                                          <div className="w-[50%] bg-pricedivc h-[26px] rounded-[7px] flex items-center text-[14px] font-medium justify-center gap-2 ">
                                            <p>{item.price}</p>
                                            <p>تومان</p>
                                          </div>
                                          <div className="w-[20%] bg-pricedivc h-[26px] rounded-[7px] flex items-center justify-center gap-1">
                                            <img
                                              alt=""
                                              className="w-[15px] h-[15px]"
                                              src={timeimg}
                                            />
                                            <p className="text-[14px] lg:text-[12px] font-medium pt-1 ">
                                              {item.time}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </>
                            )}
                          </div> */}
                          {/* <div className="space-y-3">
                            {showhis === false &&
                              showtrinprogress === true &&
                              alltravel.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      className={`h-[153px]  flex-row bg-white rounded-[10px] mx-4  border-divborderblue border-[1px] ${
                                        showexit === false
                                          ? "cursor-pointer"
                                          : ""
                                      } ${index === 0 ? "mt-7" : ""}`}
                                      onClick={
                                        showexit === false
                                          ? () =>
                                              handleshowhistroyprogress(item)
                                          : null
                                      }
                                    >
                                      <div className=" flex  items-center pr-4 pt-2">
                                        <div className="  w-[10px] h-[10px] border-[2px] border-bordercircle rounded-[50%] "></div>

                                        <p className="text-[14px] font-normal p-2">
                                          {item.city}
                                        </p>
                                      </div>
                                      <div className="mt-[-12px]">
                                        <div className="flex-col  pr-[19px]  ">
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px] "></div>
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px]  "></div>
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px]  "></div>
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px]  "></div>
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px]  "></div>
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px]  "></div>
                                          <div className="w-[4px] h-[4px] rounded-[50%] bg-bordercircle my-[2px]  "></div>
                                        </div>
                                        <div className=" flex  items-center mr-4 mt-[-10px]">
                                          <div className="w-[10px] h-[10px] rounded-[2px]  bg-bordercircle flex justify-center items-center">
                                            <div className="bg-white rounded-[2px] w-[6px] h-[6px]"></div>
                                          </div>
                                          <p className="text-[14px] font-normal p-2">
                                            {item.des}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="h-[1px] bg-lightline mx-4 mt-2"></div>
                                      <div className=" row-span-1  grid grid-cols-2 mr-4 ">
                                        <div className="col-span-1 h-[40px] flex ">
                                          <div className=" items-center  flex  text-[16px] font-medium ">
                                            <p>{item.price}</p>
                                            <p className="pr-2">تومان</p>
                                          </div>
                                        </div>
                                        <div className="col-span-1 flex justify-end h-[40px] items-center text-[12px]  font-medium">
                                          <p className="text-[12px] font-medium text-detailcolor">
                                            جزئیات
                                          </p>

                                          <div className="ml-4 cursor-pointer ">
                                            <img
                                              alt=""
                                              src={leftarrow}
                                              className="pr-2"
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div> */}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className=" h-full w-full flex flex-col   items-center justify-center ">
              <>
                <div
                  id="traveldiv"
                  className=" flex  items-center justify-center  "
                >
                  <p className="text-[20px] text-center  text-[black] font-bold">
                    در انتظار دریافت اطلاعات ...
                  </p>
                </div>
              </>
            </div>
          )}
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Historycom;
