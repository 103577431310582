import React from "react";
import "./loading.css";
const Loadingcom = () => {
  return (
    <div className="flex justify-center items-center">
      <span className=" loader w-[24px] h-[24px] "></span>
    </div>
  );
};
export default Loadingcom;
