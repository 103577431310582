import React, { useContext, useEffect } from "react";
import homeimg from "../../image/homeimg.svg";
import supportimg from "../../image/supportimg.svg";
import reportimg from "../../image/report.svg";
import historyimg from "../../image/history.svg";
import offimg from "../../image/power-off(1) 1.svg";
import hisactiveimg from "../../image/historyactive.svg";
import repactiveimg from "../../image/reportactive.svg";
import suppactiveimg from "../../image/supportactive.svg";
import homeactiveimg from "../../image/homeactive.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { UseContext } from "../../context";
import Exitcom from "./Exit";
const Nav = () => {
  const {
    changecreport,
    setChangecreport,
    changechistory,
    setChangechistory,
    changecsupport,
    setChangecsupport,
    setChangechome,
    setShowhistoryoftr,
    setShowhomecom,
    setShowhiscom,
    showsearchcom,
    changeoffbuttoncolor,
    setChangeoffbuttoncolor,
    showexit,
    setShowexit,
    setShowsearchcom,
    setShoweverytravelcard,
    shownav,
    showmessagecom,
    showerrorpageforcode,
    setShowhis,
    showhistrinprogress,
    setShownav,
    showhomecom,
    getmyprof,
  } = useContext(UseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const addressofpage = location.pathname;

  useEffect(() => {
    switch (addressofpage) {
      case "/Edit":
        setShownav(false);
        break;
      case "/home":
        setShowsearchcom(true);
        setChangechistory(false);
        setChangecreport(false);
        setShowhomecom(true);
        setChangeoffbuttoncolor(true);
        setChangecsupport(false);

        break;
      case "/support":
        setChangecsupport(true);
        setChangeoffbuttoncolor(true);
        setShowsearchcom(false);
        setChangechistory(false);
        setChangecreport(false);
        setShownav(true);
        break;
      case "/history":
        if (showhistrinprogress === true) {
          setShowhis(false);
        } else {
          setShowhis(true);
        }
        setChangechistory(true);
        setChangecreport(false);
        setShowhomecom(true);
        setChangeoffbuttoncolor(true);
        setShowsearchcom(false);
        setChangecsupport(false);
        setShownav(true);
        break;
      case "/report":
        setChangechistory(false);
        setChangecreport(true);
        setShowhomecom(true);
        setChangeoffbuttoncolor(true);
        setShowsearchcom(false);
        setChangecsupport(false);
        setShownav(true);
        break;
      case "/search":
        setChangechistory(false);
        setChangecreport(false);
        setChangeoffbuttoncolor(false);
        setShowsearchcom(true);
        setChangecsupport(false);
        setShownav(true);
        break;
      case "/history/travelhistory":
        setShownav(false);
        break;
      case "/home/travelinformation":
        setShownav(false);
        break;
      default:
    }
  }, [
    addressofpage,
    shownav,
    changecsupport,
    showsearchcom,
    changeoffbuttoncolor,
    changechistory,
    changecreport,
    showhomecom,
    showsearchcom,
  ]);
  const handlestartapp = () => {
    setShoweverytravelcard(false);
    setChangeoffbuttoncolor(!changeoffbuttoncolor);
    setShowexit(false);
    setShowhomecom(true);
    navigate("/home");
  };
  const handleoffapp = () => {
    setChangeoffbuttoncolor(!changeoffbuttoncolor);
    setShowexit(!showexit);
  };
  const historycom = () => {
    setChangecsupport(false);
    setChangechome(false);
    setShowhomecom(false);
    setShowhistoryoftr(false);
    setShowhiscom(true);
    setShowsearchcom(false);
    setShowsearchcom(false);
    navigate("/history");
  };
  const reportcom = () => {
    setChangechistory(false);
    setChangecsupport(false);
    setChangechome(false);
    setShowhomecom(false);
    setShowsearchcom(false);
    navigate("/report");
  };
  const supportcom = () => {
    setChangecsupport(true);
    setChangechistory(false);
    setChangecreport(false);
    setChangechome(false);
    setShowhomecom(false);
    setShowsearchcom(false);
    navigate("/support");
  };
  const homecom = () => {
    setShoweverytravelcard(false);
    setChangecreport(false);
    setChangechistory(false);
    setChangecsupport(false);
    setShowhomecom(true);
    navigate("/home");
  };
  return (
    <>
      {showexit && <Exitcom />}
      {showexit === false &&
        shownav === true &&
        showmessagecom === false &&
        showerrorpageforcode === false && (
          <>
            <div className="  flex flex-col justify-end items-end w-full  ">
              <div className="flex justify-center items-center w-full lg:w-1/3 fixed mb-10">
                <div className="  bg-whitecolor h-[62px] w-full mr-4 ml-4 lg:w-[100%]  rounded-[10px] mb-[32px] shadow-navboxshadow roundedt-[4px] flex justify-center items-center  grid grid-cols-5  gap-8  ">
                  {changeoffbuttoncolor === false ? (
                    <>
                      <div className="col-span-1  flex justify-center items-center   ">
                        <img alt="" src={historyimg}></img>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        onClick={historycom}
                        className="col-span-1  flex justify-center items-center cursor-pointer  "
                      >
                        <img
                          alt=""
                          src={
                            changechistory === true ? hisactiveimg : historyimg
                          }
                        ></img>
                      </div>
                    </>
                  )}

                  {changeoffbuttoncolor === false ? (
                    <>
                      {" "}
                      <div className="col-span-1  flex justify-center items-center   ">
                        <img alt="" src={reportimg}></img>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        onClick={reportcom}
                        className="col-span-1  flex justify-center items-center  cursor-pointer "
                      >
                        <img
                          alt=""
                          src={
                            changecreport === true ? repactiveimg : reportimg
                          }
                        ></img>
                      </div>
                    </>
                  )}

                  <div className="flex justify-center items-center">
                    <div className="rounded-full bg-whitecolor  flex justify-center items-center relative bottom-4 shadow-navboxshadowtop col-span-1">
                      {changeoffbuttoncolor === true ? (
                        <>
                          {" "}
                          <div
                            onClick={handleoffapp}
                            style={{
                              backgroundColor: "rgba(255, 191, 24, 1)",
                            }}
                            className="  shadow-navboxshadowtop  border-[4px] border-whitecolor  cursor-pointer h-[47px] w-[47px]  rounded-full = flex justify-center items-center"
                          >
                            <img alt="" src={offimg}></img>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div
                            onClick={handlestartapp}
                            style={{
                              backgroundColor: "rgba(119, 119, 119, 0.2)",
                            }}
                            className=" shadow-navboxshadowtop  border-[4px] border-whitecolor  cursor-pointer h-[47px] w-[47px] rounded-full flex justify-center items-center"
                          >
                            <img alt="" src={offimg}></img>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {changeoffbuttoncolor === false ? (
                    <>
                      {" "}
                      <div className="col-span-1  flex justify-center items-center   ">
                        <img alt="" src={supportimg}></img>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        onClick={supportcom}
                        className="col-span-1  flex justify-center items-center cursor-pointer  "
                      >
                        <img
                          alt=""
                          src={
                            changecsupport === true ? suppactiveimg : supportimg
                          }
                        ></img>
                      </div>
                    </>
                  )}

                  {changeoffbuttoncolor === false ? (
                    <>
                      {" "}
                      <div className="col-span-1 flex justify-center items-center  ">
                        <img
                          alt=""
                          src={showsearchcom === true ? homeactiveimg : homeimg}
                        ></img>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        onClick={homecom}
                        className="col-span-1 flex justify-center items-center  cursor-pointer"
                      >
                        <img
                          alt=""
                          src={showsearchcom === true ? homeactiveimg : homeimg}
                        ></img>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};
export default Nav;
