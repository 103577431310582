import React from "react";
import { useContext } from "react";
import { UseContext } from "../../context";
const Errorpage = () => {
  const { showerrortextcode } = useContext(UseContext);
  return (
    <>
      <div className="w-full h-[100vh]">
        <div className="h-full">
          <div className="flex justify-center items-center h-full">
            <p className="text-[red] font-bold text-[30px]">صفحه یافت نشد!</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Errorpage;
