import React, { useState } from "react";
import LoginImage from "../public/loginpicture.svg";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { message, notification } from "antd";
import Loadingcom from "../searchfortravel/allcomponent/Loading";

const Logincom = () => {
  const navigate = useNavigate();
  const [phonenumber, setPhonenumder] = useState("");
  const [loading, setloading] = useState(false);
  Cookies.set("phonenumber", phonenumber);
  const handleshowrecievecodecom = () => {
    setloading(true);
    axios
      .post("https://taxi-end.liara.run/driver/v1/send-otp", {
        mobile: phonenumber,
      })
      .then((res) => {
        if (res.status === 200) {
          setloading(false);
          notification.success({
            description: res.data.message,
          });
          navigate("/recievecode");
        }
      })
      .catch((error) => {
        setloading(false);
        if (error.response) {
          notification.error({
            message: "مشکلی پیش آمده است",
            description: error.response.data.message,
          });
          setloading(false);
        }
      });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhonenumder(value);
      setloading(false);
    }
  };

  return (
    <>
      {localStorage.getItem("token") === null ? (
        <div className="h-[100vh] w-full justify-center items-center lg:flex lg:flex-col lg:justify-center lg:items-center">
          <div className="lg:mt-10 mt-[52px] flex justify-center">
            <img alt="" src={LoginImage} className="w-[300px]" />
          </div>
          <div className="mt-7 cursor-pointer">
            <p className="text-[24px] font-normal text-center text-black">
              ورود به حساب کاربری
            </p>
          </div>
          <div className="flex flex-col md:grid md:grid-rows-2 md:grid-flow-col w-full h-96">
            <div className="mx-4 flex flex-col mt-6">
              <div className="focus:outline-gray-400 w-full flex h-[50px] bg-transparent border-[1px] border-grayborder rounded-[10px]">
                <input
                  maxLength={10}
                  onChange={handleInputChange}
                  value={phonenumber}
                  className="outline-none w-full h-[50px] rounded-[10px] border-none bg-transparent p-4 placeholder-lightgraytext text-4 font-medium"
                  placeholder="شماره موبایل"
                ></input>
                <div className="w-fit flex justify-start items-center pl-4">
                  98+
                </div>
              </div>
            </div>

            <div className="flex-grow w-full flex flex-col justify-end items-center px-4 md:px-0 mt-4">
              <button
                className="bg-[#100E34] md:w-[90%] h-[50px] rounded-[10px] w-full mb-4"
                onClick={handleshowrecievecodecom}
              >
                {loading ? (
                  <Loadingcom />
                ) : (
                  <p className="text-white text-[16px] font-medium">ورود</p>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/home" />
      )}
    </>
  );
};

export default Logincom;
