import React, { useEffect } from "react";
import backicon from "../image/backicon.svg";
import Cookies from "js-cookie";
import editimg from "../image/Edit img.svg";
import leftarrow from "../image/Arrow - Left 2.svg";
import logoutimg from "../image/log out img.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UseContext } from "../context";
import Loadingcom from "./allcomponent/Loading";
const EditProfile = () => {
  const phonenumber = Cookies.get("phonenumber");
  const {
    setShoweditprof,
    setShownav,
    Personalityinf,
    input1,
    input2,
    input3,
    input4,
    Carvalue,
    setNationalnum,
    setAdress,
    setCarName,
    setCarModel,
    setCarColor,
    setVehicleid,
    setDriving_licenceid,
    setNational_card,
    setCar_card_photo,
    setPicture_of_the_insurance_policy,
    setId,
    setFathername,
    setPersonalityinf,
    setCarValue,
    setDriverimg,
    setImage2,
    setImage3,
    setImage4,
    setFile,
    setImage1,
    setInput1,
    setInput2,
    setInput3,
    setInput4,
    setProfileImg,
    setGetmyprof,
    getprofloading,
    setGetprofloading,
    getmyprof,
    setImg1,
    setImgloading2,
    setImgloading3,
    setImg2,
    setImg3,
    setImg4,
    setImgloading4,
    setImgloading5,
    changecreport,
    changechistory,
    changecsupport,
  } = useContext(UseContext);
  const navigate = useNavigate();
  function handlelogout() {
    setNationalnum("");
    setGetprofloading("");
    setAdress("");
    setCarName("");
    setCarModel("");
    setCarColor("");
    setVehicleid("");
    setDriving_licenceid("");
    setNational_card("");
    setCar_card_photo("");
    setPicture_of_the_insurance_policy("");
    setId("");
    setFathername("");
    setPersonalityinf("");
    setCarValue("");
    setDriverimg("");
    setImage2("");
    setImage3("");
    setImage4("");
    setFile("");
    setImage1("");
    setInput1("");
    setInput2("");
    setInput3("");
    setInput4("");
    setProfileImg("");
    setGetmyprof("");
    setImg1(false);
    setImgloading2(false);
    setImg2(false);
    setImgloading3(false);
    setImg3(false);
    setImg4(false);
    setImgloading5(false);
    setImgloading4(false);
    localStorage.clear();
    setShoweditprof(false);
    navigate("/");
  }
  function handleshoweditpage() {
    navigate("/EditDriverProfile");
  }
  function handleback() {
    setShownav(true);
    if (changecsupport === true) {
      navigate("/support");
    } else if (changechistory === true) {
      navigate("/history");
    } else if (changecreport === true) {
      navigate("/report");
    } else {
      navigate("/home");
    }
  }
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <div className="w-full h-full  ">
          {getprofloading === false ? (
            <>
              {getmyprof.status === "profile_not_completed" ? (
                <Navigate to="/UserandCarinformation" />
              ) : (
                <>
                  {" "}
                  <img
                    onClick={handleback}
                    alt=""
                    className="pr-4 mt-6 cursor-pointer"
                    src={backicon}
                  ></img>
                  <div className="flex w-full justify-center   ">
                    <img
                      className="w-[90px] h-[90px] flex items-center justify-center rounded-[50%]"
                      src={`https://taxi-end.liara.run/${getmyprof.profile_image.fileName}`}
                    ></img>
                  </div>
                  <p className="text-center text-[16px] font-medium text-namecolor">
                    {getmyprof.full_name}
                  </p>
                  <p className="text-center text-[12px] font-normal text-namecolor ">
                    0{getmyprof.mobile}
                  </p>
                  <div className="flex mx-4 gap-4 mt-6 justify-between ">
                    <div className="w-[100%] bg-CarNC rounded-[10px] h-[40px] flex items-center">
                      <p className="text-[14px] font-medium pr-4">
                        {getmyprof.vehicle.brand?.name}
                      </p>
                    </div>
                    <div
                      className="w-[132px] bg-white h-[40px] rounded-[5px] border-[1px] border-plateborder flex justify-between"
                      dir="ltr"
                    >
                      <div className="flex   " dir="ltr">
                        <div className=" flex items-center  text-[16px] font-medium ml-2 ">
                          <p>{getmyprof.vehicle.plate_code?.part1}</p>
                          <p className="px-1">
                            {getmyprof.vehicle.plate_code?.part2}
                          </p>
                          {""}
                          <p>{getmyprof.vehicle.plate_code?.part3}</p>
                        </div>
                      </div>
                      <div className=" border-l-[1px] border-plateborder m-1"></div>
                      <div className="flex-col font-medium text-center mr-1">
                        <p className="text-[6px] ">ایران</p>
                        <div className="flex items-center justify-center">
                          <p className="text-[16px]">
                            {getmyprof.vehicle.plate_code?.part4}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-[1px] border-bordergrayC h-[1px] mt-6 mx-4"></div>
                  <div
                    className="flex  justify-between cursor-pointer"
                    onClick={handleshoweditpage}
                  >
                    <div className="m-4 flex ">
                      <img alt="" src={editimg}></img>
                      <p className="text-[16px] font-normal mr-3">
                        ویرایش اطلاعات
                      </p>
                    </div>
                    <img alt="" className="ml-4" src={leftarrow}></img>
                  </div>
                  <div className="border-[1px] border-bordergrayC h-[1px]  mx-4"></div>
                  <div
                    className="m-4 flex cursor-pointer"
                    onClick={handlelogout}
                  >
                    <img alt="" src={logoutimg}></img>
                    <p className="text-[16px] font-normal mr-3 text-logoutTC">
                      خروج از حساب
                    </p>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className=" h-full w-full flex flex-col   items-center justify-center ">
              <>
                <div
                  id="reportdiv"
                  className=" flex  items-center justify-center  "
                >
                  <p className="text-[20px] text-center  text-[black] font-bold">
                    در انتظار دریافت اطلاعات ...
                  </p>
                </div>
                <div className="mt-4">
                  <Loadingcom />
                </div>
              </>
            </div>
          )}
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
export default EditProfile;
