import "./App.css";
import { Routes, Route } from "react-router-dom";
import Search from "./searchfortravel/Search";
import Historycom from "./searchfortravel/Historycom";
import TravelCard from "./searchfortravel/TravelCard";
import Reportcom from "./searchfortravel/Reportcom";
import Logincom from "./LoginRegister/Login";
import Recievecode from "./LoginRegister/Recievecode";
import Everyhistravelcard from "./searchfortravel/Everyhistravelcard";
import Everytravelcard from "./searchfortravel/Everytravelcard";
import UserandCarinf from "./LoginRegister/User&Carinf";
import Messagecom from "./searchfortravel/Messagecom";
import Nav from "./searchfortravel/allcomponent/Nav";
import { useContext, useEffect } from "react";
import { UseContext } from "./context";
import { useLocation } from "react-router-dom";
import Supportcom from "../src/searchfortravel/Supportcom";
import Errorpage from "./searchfortravel/allcomponent/404page";
import axios from "axios";
import EditProfile from "./searchfortravel/EditProfile";
import EditDriverProfile from "./LoginRegister/EditDriverProfile";
function App() {
  const {
    shownav,
    setShownav,
    setShoweverytravelcard,
    setShowhomecom,
    setShowmessagecom,
    setShowerrorpageforcode,
    setShowexit,
    showhistrinprogress,
    setShowhis,
    setShowhistrinprogress,
    setLoading,
    setShowerrortextcode,
    setGetmyprof,
    setShoweditprof,
    getprofloading,
    setGetprofloading,
    getmyprof,
  } = useContext(UseContext);
  const location = useLocation();
  const addressofpage = location.pathname;
  useEffect(() => {
    if (addressofpage === "/" || addressofpage === "/recievecode") {
      return;
    } else {
      axios
        .get("https://taxi-end.liara.run/driver/v1/get-my-profile", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setGetmyprof(res.data.data);
          setGetprofloading(false);
        })
        .catch((error) => {});
    }
  }, [addressofpage]);

  useEffect(() => {
    switch (addressofpage) {
      case "/Edit":
        setShownav(false);
        break;

      case "/home":
        setShowerrorpageforcode(false);
        setShoweverytravelcard(false);
        setShowhomecom(true);
        setShowmessagecom(false);
        setShowexit(false);
        setShoweditprof(false);

        if (localStorage.getItem("token") === null) {
          setShownav(false);
        } else {
          setShownav(true);
        }
        break;
      case "/history":
        setShowhistrinprogress(false);
        setShowerrorpageforcode(false);
        setShowhomecom(false);
        setShoweditprof(false);
        if (showhistrinprogress === true) {
          setShowhis(false);
          setShowhistrinprogress(true);
        } else {
          setShowhis(true);
          setShowhistrinprogress(false);
        }

        setShowexit(false);
        if (localStorage.getItem("token") === null) {
          setShownav(false);
        } else {
          setShownav(true);
        }
        break;
      case "/report":
        setShowerrorpageforcode(false);
        setShowhomecom(true);
        setShowmessagecom(false);
        setShowexit(false);
        setShoweditprof(false);
        if (localStorage.getItem("token") === null) {
          setShownav(false);
        } else {
          setShownav(true);
        }
        break;
      case "/search":
        setShowerrorpageforcode(false);
        setShowexit(false);
        setShoweditprof(false);
        if (localStorage.getItem("token") === null) {
          setShownav(false);
        } else {
          setShownav(true);
        }
        break;
      case "/support":
        setShowerrorpageforcode(false);
        setShowexit(false);
        setShoweditprof(false);
        if (localStorage.getItem("token") === null) {
          setShownav(false);
        } else {
          setShownav(true);
        }
        break;
      case "/report/Message":
        setShownav(false);
        break;
      case "/history/travelhistory":
        setShownav(false);
        setShowerrortextcode(false);
        break;
      case "/home/travelinformation":
        setShownav(false);
        setShowerrortextcode(false);
        break;
      case "/UserandCarinformation":
        setShownav(false);
        break;
      default:
        setShownav(false);
    }
  }, [
    addressofpage,
    setShownav,
    setShowerrortextcode,
    setShowerrorpageforcode,
    setShowexit,
    setShowmessagecom,
    setShowhomecom,
    setShowhistrinprogress,
    setLoading,
    setShoweverytravelcard,
    setShowhis,
    showhistrinprogress,
  ]);
  return (
    <>
      {" "}
      <div className="flex justify-center items-center lg:items-center lg:h-screen lg:my-2  ">
        <div className="  lg:h-screen h-[100vh] flex flex-col justify-center items-center lg:w-1/3  w-full lg:border-gray-500 lg:border-[1px] lg:rounded-[10px] ">
          <>
            <Routes>
              <Route path="/" element={<Logincom />} />
              <Route path="/search" element={<Search />}></Route>
              <Route path="/history" element={<Historycom />}></Route>
              <Route path="/home" element={<TravelCard />}></Route>
              <Route path="/report" element={<Reportcom />}></Route>
              <Route path="/support" element={<Supportcom />}></Route>
              <Route path="/recievecode" element={<Recievecode />}></Route>
              <Route
                path="/history/travelhistory"
                element={<Everyhistravelcard />}
              ></Route>

              <Route
                path="/home/travelinformation"
                element={<Everytravelcard />}
              ></Route>
              <Route
                path="/UserandCarinformation"
                element={<UserandCarinf />}
              ></Route>
              <Route path="/report/Message" element={<Messagecom />}></Route>
              <Route
                path="/EditDriverProfile"
                element={<EditDriverProfile />}
              ></Route>
              <Route path="/Edit" element={<EditProfile />}></Route>
              <Route path="*" element={<Errorpage />} />
            </Routes>
            {shownav === true ? <Nav /> : <></>}
          </>
        </div>
      </div>
    </>
  );
}
export default App;
