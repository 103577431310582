import { createContext, useState } from "react";

export const UseContext = createContext();

function USECONTEXT({ children }) {
  const [Carvalue, setCarValue] = useState("");
  const [driverimg, setDriverimg] = useState(false);
  const [img1, setImg1] = useState(false);
  const [img2, setImg2] = useState(false);
  const [img3, setImg3] = useState(false);
  const [img4, setImg4] = useState(false);
  const [Personalityinf, setPersonalityinf] = useState("");
  const [Fathername, setFathername] = useState("");
  const [Id, setId] = useState("");
  const [Nationalnum, setNationalnum] = useState("");
  const [Address, setAdress] = useState("");
  const [CarName, setCarName] = useState("");
  const [CarModel, setCarModel] = useState("");
  const [CarColor, setCarColor] = useState("");
  const [vehicleid, setVehicleid] = useState("");
  const [driving_licenceid, setDriving_licenceid] = useState("");
  const [national_card, setNational_card] = useState("");
  const [car_card_photo, setCar_card_photo] = useState("");
  const [picture_of_the_insurance_policy, setPicture_of_the_insurance_policy] =
    useState("");
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [File, setFile] = useState("");
  const [allcarbrand, setAllcarbrand] = useState([]);
  const [imgloading1, setImgloading1] = useState(false);
  const [imgloading2, setImgloading2] = useState(false);
  const [imgloading3, setImgloading3] = useState(false);
  const [imgloading4, setImgloading4] = useState(false);
  const [imgloading5, setImgloading5] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showregistercom, setShowregistercom] = useState(false);
  const [showlogincom, setShowlogincom] = useState(false);
  const [changechistory, setChangechistory] = useState(false);
  const [changecreport, setChangecreport] = useState(false);
  const [changecsupport, setChangecsupport] = useState(false);
  const [changechome, setChangechome] = useState(false);
  const [showhomecom, setShowhomecom] = useState(false);
  const [showeverytravelcard, setShoweverytravelcard] = useState(false);
  const [everytravelcinformation, setEverytravelcinformation] = useState([]);
  const [showhistorycom, setShowhistorycom] = useState(false);
  const [Showhistoryoftr, setShowhistoryoftr] = useState(false);
  const [everyhiscard, setEveryhiscard] = useState([]);
  const [Showhiscom, setShowhiscom] = useState(true);
  const [showmessagecom, setShowmessagecom] = useState(false);
  const [showreportcom, setShowreportcom] = useState(true);
  const [showsearchcom, setShowsearchcom] = useState(true);
  const [changeoffbuttoncolor, setChangeoffbuttoncolor] = useState(false);
  const [showexit, setShowexit] = useState(false);
  const [allcity, setAllcity] = useState([]);
  const [shownav, setShownav] = useState(false);
  const [showtravelinfaddress, setShowtravelinfaddress] = useState(false);
  const [showerrortextcode, setShowerrortextcode] = useState(true);
  const [showerrorpageforcode, setShowerrorpageforcode] = useState(true);
  const [showhis, setShowhis] = useState(false);
  const [showtrinprogress, setShowtrinprogress] = useState(false);
  const [showhistrinprogress, setShowhistrinprogress] = useState(false);
  const [showeditprof, setShoweditprof] = useState(false);
  const [profileImg, setProfileImg] = useState("");

  const [getmyprof, setGetmyprof] = useState({});
  const [alltravel, setAlltravel] = useState([
    {
      city: "اصفهان",
      des: "تهران",
      date: "1402/4/12",
      price: "230000",
      time: "14:14",
    },
    {
      city: "اصفهان",
      des: "تهران",
      date: "1402/4/12",
      price: "230000",
      time: "14:14",
    },
    {
      city: "اصفهان",
      des: "تهران",
      date: "1402/4/8",
      price: "230000",
      time: "14:14",
    },
    {
      city: "اصفهان",
      des: "تهران",
      date: "1402/4/9",
      price: "230000",
      time: "14:15",
    },
  ]);
  const [statususer, setStatususer] = useState("");
  const [getprofloading, setGetprofloading] = useState(true);
  const contextValue = {
    Nationalnum,
    setNationalnum,
    Address,
    setAdress,
    CarName,
    setCarName,
    CarModel,
    setCarModel,
    CarColor,
    setCarColor,
    vehicleid,
    setVehicleid,
    driving_licenceid,
    setDriving_licenceid,
    national_card,
    setNational_card,
    car_card_photo,
    setCar_card_photo,
    picture_of_the_insurance_policy,
    setPicture_of_the_insurance_policy,
    img4,
    setImg4,
    Id,
    setId,
    Fathername,
    setFathername,
    Personalityinf,
    setPersonalityinf,
    Carvalue,
    setCarValue,
    driverimg,
    setDriverimg,
    img1,
    setImg1,
    img2,
    setImg2,
    img3,
    setImg3,
    allcarbrand,
    setAllcarbrand,
    imgloading1,
    setImgloading1,
    imgloading2,
    setImgloading2,
    imgloading3,
    setImgloading3,
    imgloading4,
    setImgloading4,
    imgloading5,
    setImgloading5,
    image2,
    setImage2,
    image3,
    setImage3,
    image4,
    setImage4,
    File,
    setFile,
    image1,
    setImage1,
    input1,
    setInput1,
    input2,
    setInput2,
    input3,
    setInput3,
    input4,
    setInput4,
    alltravel,
    setAlltravel,
    changecreport,
    setChangecreport,
    changechistory,
    setChangechistory,
    changecsupport,
    setChangecsupport,
    changechome,
    setChangechome,
    showhomecom,
    setShowhomecom,
    showeverytravelcard,
    setShoweverytravelcard,
    everytravelcinformation,
    setEverytravelcinformation,
    showhistorycom,
    setShowhistorycom,
    Showhistoryoftr,
    setShowhistoryoftr,
    everyhiscard,
    setEveryhiscard,
    Showhiscom,
    setShowhiscom,
    showmessagecom,
    setShowmessagecom,
    showreportcom,
    setShowreportcom,
    showsearchcom,
    setShowsearchcom,
    changeoffbuttoncolor,
    setChangeoffbuttoncolor,
    showregistercom,
    setShowregistercom,
    showlogincom,
    setShowlogincom,
    showexit,
    setShowexit,
    allcity,
    setAllcity,
    shownav,
    setShownav,
    loading,
    setLoading,
    showtravelinfaddress,
    setShowtravelinfaddress,
    showerrorpageforcode,
    setShowerrorpageforcode,
    showerrortextcode,
    setShowerrortextcode,
    showhis,
    setShowhis,
    showtrinprogress,
    setShowtrinprogress,
    showhistrinprogress,
    setShowhistrinprogress,
    showeditprof,
    setShoweditprof,
    profileImg,
    setProfileImg,
    getmyprof,
    setGetmyprof,
    statususer,
    setStatususer,
    getprofloading,
    setGetprofloading,
  };

  return (
    <UseContext.Provider value={contextValue}>{children}</UseContext.Provider>
  );
}

export default USECONTEXT;
