import React from "react";
import money from "../image/money.svg";
import problemsimg from "../image/problems img.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UseContext } from "../context";
import Loadingcom from "./allcomponent/Loading";
const Supportcom = () => {
  const navigate = useNavigate();
  const {
    showeditprof,
    getmyprof,

    getprofloading,
  } = useContext(UseContext);
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {getprofloading === false ? (
            <>
              {getmyprof.status === "profile_not_completed" ? (
                <Navigate to="/UserandCarinformation" />
              ) : (
                <>
                  {getmyprof.status === "pending" ? (
                    <div className="w-full flex flex-col h-full  overflow-auto mb-[100px] lg:mb-[88px]">
                      <div className=" h-full w-full flex flex-col   items-center justify-center ">
                        <>
                          <div
                            id="supportdiv"
                            className=" flex  items-center justify-center  "
                          >
                            <p className="text-[20px] text-center  text-[black] font-bold">
                              حساب کاربری شما هنوز تایید نشده است!
                            </p>
                          </div>
                          <div className="mt-4">
                            <Loadingcom />
                          </div>
                        </>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="supportdiv"
                      className="h-full w-full overflow-auto mb-[100px] lg:mb-[92px]"
                    >
                      <div className="mt-12 flex cursor-pointer ">
                        <p className="text-[16px] font-medium pr-4">پشتیبانی</p>
                      </div>
                      <div className="h-[104px] bg-bordercircle rounded-[10px] mx-4 mt-7">
                        <div className="flex justify-center ">
                          <img alt="" className=" mt-4" src={money}></img>
                        </div>
                        <p className="text-[14px] font-normal text-center pt-2">
                          پرداخت کرایه
                        </p>
                      </div>
                      <div className="h-[104px] bg-bordercircle rounded-[10px] mx-4 mt-5">
                        <div className="flex justify-center ">
                          <img alt="" className=" mt-4" src={problemsimg}></img>
                        </div>
                        <p className="text-[14px] font-normal text-center pt-2">
                          مشکلات برنامه
                        </p>
                      </div>
                      <p className="text-[16px] font-medium pr-4 pt-6">
                        موضوعات پر تکرار
                      </p>
                      <div className="mx-4 ">
                        {/* <p className="text-[14px] font-normal text-supporttext break-words ">
    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
    استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
    در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
    صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه
    روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
    صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه
    روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
    صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه
    روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
    صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه
    روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
    صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه
    روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... که لازم است... لورم ایپسوم متن ساختگی با تولید
    سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
    چاپگرها و متون بلکه روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... که لازم است... لورم ایپسوم متن ساختگی با تولید
    سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
    چاپگرها و متون بلکه روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... که لازم است... لورم ایپسوم متن ساختگی با تولید
    سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
    چاپگرها و متون بلکه روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است... که لازم است... لورم ایپسوم متن ساختگی با تولید
    سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
    چاپگرها و متون بلکه روزنامه و مجله در ستون و
    سvcdffdhhhhhjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeطرآنچنان
    که لازم است...
  </p> */}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className=" h-full w-full flex flex-col   items-center justify-center ">
              <>
                <div
                  id="supportdiv"
                  className=" flex  items-center justify-center  "
                >
                  <p className="text-[20px] text-center  text-[black] font-bold">
                    در انتظار دریافت اطلاعات ...
                  </p>
                </div>
              </>
            </div>
          )}
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Supportcom;
