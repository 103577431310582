import React from "react";
import { UseContext } from "../../context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
const Exitcom = () => {
  const {
    setShowexit,
    setChangeoffbuttoncolor,
    setChangechistory,
    setChangecreport,
    setChangecsupport,
    setShowsearchcom,
    setShowhistoryoftr,
  } = useContext(UseContext);
  const navigate = useNavigate();
  const handleshowmaincom = () => {
    setShowexit(false);
    setChangeoffbuttoncolor(true);
  };

  const handleoffmaincom = () => {
    setShowhistoryoftr(false);
    setChangechistory(false);
    setChangecreport(false);
    setChangecsupport(false);
    setShowsearchcom(true);
    setShowexit(false);
    setChangeoffbuttoncolor(false);
    navigate("/search");
  };
  return (
    <>
      <div className="w-full bg-white h-[262px]  rounded-b-[10px] rounded-t-[52px] flex justify-center  ">
        <div className="grid grid-rows-4 grid-flow-col w-full h-full ">
          <div className="row-span-1 flex justify-center items-center">
            {" "}
            <div className="w-[60px] h-[3px] rounded-[3px] bg-colorofline mb-3"></div>
          </div>
          <div className="row-span-1 mr-4">
            <p className="text-[18px] font-medium text-applyingbuttoncolor   ">
              خارج شدن از دسترس
            </p>
            <div className="mt-6 text-[12px]">
              <p className="text-graytextforexit sm:text-[12px] md:text-[16px] xl:text-[16px] lg:text-[11px] font-normal  whitespace-nowrap">
                آیا مطمئنید که دیگر نمیخواهید درخواست سفر جدیدی دریافت کنید؟
              </p>
            </div>
          </div>

          <div className="row-span-1 h-full">
            <div className="w-[100%] mt-11">
              <div className="border-[1px] mr-4 ml-4  h-[1px] border-[rgba(0, 0, 0, 0.2)] "></div>
            </div>
          </div>
          <div className="row-span-1  ">
            <div className="grid grid-cols-2    w-full  ">
              <div
                className="col-span-1 w-full  flex justify-center items-center cursor-pointer "
                onClick={handleshowmaincom}
              >
                <p className="text-[16px] font-normal text-bluetext">خیر</p>
              </div>
              <div className="col-span-1 w-full flex justify-center items-center">
                <div
                  className="w-[110px] h-[40px] bg-offdivcolor rounded-[5px] flex justify-center items-center cursor-pointer"
                  onClick={handleoffmaincom}
                >
                  {" "}
                  <p className="text-[16px] font-normal text-center whitespace-nowrap text-offtextred">
                    خاموش کردن
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Exitcom;
