import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { UseContext } from "../context";
import { useContext } from "react";
import { notification } from "antd";
import axios from "axios";
import Loadingcom from "../searchfortravel/allcomponent/Loading";
import { Navigate } from "react-router-dom";

const Recievecode = () => {
  const {
    setShownav,
    setShowerrortextcode,
    getmyprof,
    getprofloading,
    setGetprofloading,
    setGetmyprof,
  } = useContext(UseContext);
  const [loading, setloading] = useState(false);
  let navigate = useNavigate();
  const phonenumber = Cookies.get("phonenumber");

  const handleshoweditprof = () => {
    setloading(true);
    setShownav(false);
    axios
      .post("https://taxi-end.liara.run/driver/v1/submit-otp", {
        mobile: phonenumber,
        code: Otpnum,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.data.tokens.accessToken);

          getmyprofile();
        }
      })
      .catch((error) => {
        if (error.response) {
          notification.error({
            message: "مشکلی پیش آمده است",
            description: error.response.data.message,
          });
          setloading(false);
        }
      });
  };

  function getmyprofile() {
    axios
      .get("https://taxi-end.liara.run/driver/v1/get-my-profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setGetmyprof(res.data.data);
        setGetprofloading(false);
        checkProfileStatus();
      })
      .catch((error) => {
        setGetprofloading(true);
      });
  }
  const checkProfileStatus = () => {
    if (getprofloading === true) {
      // setloading(true);
      return;
    } else if (getprofloading === false) {
      if (getmyprof) {
        if (getmyprof.status === "profile_not_completed") {
          navigate("/UserandCarinformation");
        } else {
          navigate("/home");
        }
      } else {
        notification.error({
          message: "خطا",
          description: "داده‌های پروفایل کاربر دریافت نشد.",
        });
      }
    }
  };

  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value.length === 1 && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      if (newOtp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
        newOtp[index - 1] = "";
      } else {
        newOtp[index] = "";
      }
      setOtp(newOtp);
    }
  };

  const Otpnum = otp.join("");

  return (
    <>
      {localStorage.getItem("token") === null ? (
        <>
          {phonenumber !== "" ? (
            <div className="h-full w-full flex flex-col justify-center relative items-center lg:flex lg:flex-col lg:justify-center lg:items-center">
              <div className="mt-10">
                <p className="text-[24px] font-normal text-black">
                  کد تایید را وارد کنید.
                </p>
              </div>
              <div className="mt-6">
                <p className="text-graytext md:text-[18px] text-[16px] font-medium">
                  کد تأیید به شمارهٔ
                  <span className="text-black"> {phonenumber} </span> ارسال شد.
                </p>
              </div>
              <div className="flex mt-6 px-2">
                <div
                  className="flex justify-center flex-row-reverse gap-2"
                  id="inputs"
                >
                  {otp.map((digit, index) => (
                    <input
                      ref={(el) => (inputRefs.current[index] = el)}
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => {
                        handleOtpChange(index, e.target.value);
                        setloading(false);
                      }}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className={`outline-none text-[26px] md:w-[15%] md:h-[55px] w-[48px] h-[48px] text-center border border-gray-300 rounded-md focus:outline-none ${
                        digit ? "input-filled" : ""
                      }`}
                      style={{ direction: "ltr" }}
                      pattern="[0-9]"
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
              </div>

              <div className="flex-grow w-full relative  flex flex-col justify-end items-center px-4 md:px-0 mt-4 ">
                <button
                  className="bg-[#100E34] fixed lg:w-[30%]   w-[90%]  h-[50px] rounded-[10px] mb-[100px]  "
                  onClick={handleshoweditprof}
                >
                  {loading === false ? (
                    <p className="text-white text-[16px] font-medium">تایید</p>
                  ) : (
                    <Loadingcom />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <Navigate to="/" />
          )}
        </>
      ) : (
        <Navigate to="/home" />
      )}
    </>
  );
};

export default Recievecode;
