import React, { useEffect } from "react";
import backicon from "../image/backicon.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Errorpage from "./allcomponent/404page";
import { useContext } from "react";
import { UseContext } from "../context";
import nmp_mapboxgl from "@neshan-maps-platform/mapbox-gl";
import { MapComponent, MapTypes } from "@neshan-maps-platform/mapbox-gl-react";
import "@neshan-maps-platform/mapbox-gl-react/dist/style.css";
import "./Scroll.css";
const Everyhistravelcard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const { setShowhis, showhistrinprogress } = useContext(UseContext);
  const handlecloseeveryhiscom = () => {
    navigate("/history");
    setShowhis(true);
    if (showhistrinprogress === true) {
      setShowhis(false);
    }
  };
  useEffect(() => {
    if (data !== null) {
      const neshanMap = new nmp_mapboxgl.Map({
        mapType: nmp_mapboxgl.Map.mapTypes.neshanVector,
        container: "map",
        zoom: 14,
        pitch: 0,
        center: [35.74875391151226, 51.379671542050204],
        minZoom: 2,
        maxZoom: 21,
        trackResize: true,
        mapKey: "web.eac958c29cec49699156fa02733fb8b3",
        poi: true,
        traffic: false,
        isZoomControlsEnabled: true,
        setZoomGesturesEnabled: true,
        mapTypeControllerOptions: {
          show: false,
        },
      });

      neshanMap.addControl(
        new nmp_mapboxgl.NavigationControl(),
        "bottom-right"
      );

      new nmp_mapboxgl.Marker()
        .setLngLat([35.74875391151226, 51.379671542050204])
        .addTo(neshanMap);
    }
  }, [data]);
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {data !== null ? (
            <>
              {" "}
              <div className="w-full h-full overflow-auto  flex-row ">
                <div className="row-span-1 mt-12 mr-4 ">
                  <img
                    alt=""
                    onClick={handlecloseeveryhiscom}
                    className="cursor-pointer"
                    src={backicon}
                  ></img>
                </div>
                <div className="row-span-1 mt-4 mx-4 ">
                  <div className="w-full h-[250px] ">
                    <MapComponent
                      id="map"
                      options={{
                        mapKey: "web.48d4c2ad480f4459a65222c590a47fe3",
                        mapType: MapTypes.neshanVector,
                        mapTypeControllerOptions: {
                          show: false,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row-span-1 mt-8">
                  <div className="border-[1px] mr-4 ml-4 h-[1px] border-[rgba(0, 0, 0, 0.2)] "></div>
                </div>
                <div className="flex-row space-y-[4px]">
                  <div className="row-span-1 mt-4">
                    <div className="grid  grid-cols-2">
                      <div className=" col-span-1   mr-4 text-[14px]">
                        <p> {data.date} </p>
                      </div>
                      <div className=" col-span-1    flex justify-end ml-4 text-[14px]">
                        <p> {data.time} </p>
                      </div>
                    </div>
                  </div>
                  <div className="row-span-1 flex  w-full p-4 gap-3">
                    <p className=" font-medium">مبدا:</p>
                    <p className="font-normal">{data.city}</p>
                  </div>
                  <div className="row-span-1 flex  w-full p-4 gap-3">
                    <p className=" font-medium">مقصد:</p>
                    <p className="font-normal">{data.des}</p>
                  </div>
                </div>
                <div className="row-span-1 mt-2">
                  <div className="border-[1px] mr-4 ml-4 h-[1px] border-[rgba(0, 0, 0, 0.2)] "></div>
                </div>
                <div className="row-span-1 grid grid-cols-2 text-[14px] font-medium">
                  <div className="col-span-1 ">
                    <p className="mr-4 mt-3  text-black ">خالص دریافتی</p>
                  </div>
                  <div className="col-span-1 text-greentext flex mt-4 justify-end  ml-4 gap-2">
                    <p className="">{data.price}</p>
                    <p>تومان</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            navigate("/history")
          )}
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Everyhistravelcard;
