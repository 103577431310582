import React from "react";
import { UseContext } from "../context";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./Scroll.css";
import Loadingcom from "./allcomponent/Loading";
const Reportcom = () => {
  const navigate = useNavigate();
  const {
    setShowmessagecom,
    showmessagecom,
    showreportcom,
    showexit,
    getmyprof,
    getprofloading,
  } = useContext(UseContext);
  const handleshowmessagecom = () => {
    navigate("/report/Message");
    setShowmessagecom(true);
  };
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {getprofloading === false ? (
            <>
              {getmyprof.status === "profile_not_completed" ? (
                <Navigate to="/UserandCarinformation" />
              ) : (
                <>
                  {getmyprof.status === "pending" ? (
                    <div className="w-full flex flex-col h-full  overflow-auto mb-[100px] lg:mb-[88px]">
                      <div className=" h-full w-full flex flex-col   items-center justify-center ">
                        <>
                          <div
                            id="reportdiv"
                            className=" flex  items-center justify-center  "
                          >
                            <p className="text-[20px] text-center  text-[black] font-bold">
                              حساب کاربری شما هنوز تایید نشده است!
                            </p>
                          </div>
                          <div className="mt-4">
                            <Loadingcom />
                          </div>
                        </>
                      </div>
                    </div>
                  ) : (
                    <>
                      {showmessagecom === false && (
                        <div
                          id="reportdiv"
                          className="w-full h-full mb-[160px]  overflow-auto "
                        >
                          {/* {showreportcom && (
                            <div
                              className="ml-4 mr-4 space-y-4 h-[80%]  "
                              onClick={
                                showexit === false
                                  ? () => handleshowmessagecom()
                                  : null
                              }
                            >
                              <div
                                className={`w-full   h-[116px] bg-whitecolor mt-4 rounded-[10px] border-[1px] border-black ${
                                  showexit === false ? "cursor-pointer" : ""
                                }`}
                              >
                                <p className="text-center text-[16px] font-normal  text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                  سفر تهران اصفهان
                                </p>
                              </div>
                              <div className=" space-y-4">
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor   rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor   rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران شیراز
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor    rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor   rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor   rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor   rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر اصفهان قم
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor    rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor   rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor  rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor  rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  {" "}
                                  <div
                                    className={`w-full   h-[116px] bg-whitecolor  rounded-[10px] border-[1px] border-black ${
                                      showexit === false ? "cursor-pointer" : ""
                                    }`}
                                  >
                                    <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                      سفر تهران اصفهان
                                    </p>
                                  </div>
                                </div>{" "}
                                <div
                                  className={`w-full   h-[116px] bg-whitecolor  rounded-[10px] border-[1px] border-black ${
                                    showexit === false ? "cursor-pointer" : ""
                                  }`}
                                >
                                  <p className="text-center text-[16px] font-normal text-[rgba(119, 119, 119, 0.2)] flex justify-center items-center relative top-11">
                                    سفر تهران اصفهان
                                  </p>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className=" h-full w-full flex flex-col   items-center justify-center ">
              <>
                <div
                  id="reportdiv"
                  className=" flex  items-center justify-center  "
                >
                  <p className="text-[20px] text-center  text-[black] font-bold">
                    در انتظار دریافت اطلاعات ...
                  </p>
                </div>
              </>
            </div>
          )}
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Reportcom;
