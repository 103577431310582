import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import flash from "../image/flash.svg";
import uploadimg from "../image/upload img.svg";
import Loadingcom from "../../src/searchfortravel/allcomponent/Loading";
import flashrotate from "../image/flashrotate.svg";
import { message, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UseContext } from "../context";
import "../searchfortravel/Scroll.css";
import backicon from "../image/backicon.svg";
import { Navigate } from "react-router-dom";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import gregorian from "react-date-object/calendars/gregorian";
import "react-multi-date-picker/styles/layouts/mobile.css";
import persian_en from "react-date-object/locales/persian_en";
import gregorian_en from "react-date-object/locales/gregorian_en";
import addicon from "../image/add-52.svg";
import moment from "jalali-moment";
import logoutimg from "../image/log out img.svg";
const UserandCarinf = () => {
  const [Validation, setValidation] = useState(false);
  const {
    loading,
    setLoading,
    Nationalnum,
    setNationalnum,
    Address,
    setAdress,
    CarName,
    setCarName,
    CarModel,
    setCarModel,
    CarColor,
    setCarColor,
    vehicleid,
    setVehicleid,
    driving_licenceid,
    setDriving_licenceid,
    national_card,
    setNational_card,
    car_card_photo,
    setCar_card_photo,
    picture_of_the_insurance_policy,
    setPicture_of_the_insurance_policy,
    img4,
    setImg4,
    Id,
    setId,
    Fathername,
    setFathername,
    Personalityinf,
    setPersonalityinf,
    Carvalue,
    setCarValue,
    driverimg,
    setDriverimg,
    img1,
    setImg1,
    img2,
    setImg2,
    img3,
    setImg3,
    allcarbrand,
    setAllcarbrand,
    imgloading1,
    setImgloading1,
    imgloading2,
    setImgloading2,
    imgloading3,
    setImgloading3,
    imgloading4,
    setImgloading4,
    imgloading5,
    setImgloading5,
    image2,
    setImage2,
    image3,
    setImage3,
    image4,
    setImage4,
    File,
    setFile,
    image1,
    setImage1,
    input1,
    setInput1,
    input2,
    setInput2,
    input3,
    setInput3,
    input4,
    setInput4,
    profileImg,
    setProfileImg,
    getmyprof,
    setGetmyprof,
    setGetprofloading,
    statususer,
    getprofloading,
  } = useContext(UseContext);
  const plate_code = `${input2}${input1}${input3}${input4}`;
  const [shownextstep, setShownextstep] = useState(false);
  const [loadingprof, setLoadingprof] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [showgender, setShowgender] = useState(false);
  const [sendDate, setSendDate] = useState("");

  const isFormValid1 = () => {
    return (
      Personalityinf.trim() !== "" &&
      Fathername.trim() !== "" &&
      Nationalnum.trim() !== "" &&
      Address.trim() !== "" &&
      national_card.trim() !== "" &&
      driving_licenceid.trim() !== "" &&
      imgloading1 === false &&
      imgloading2 === false &&
      imgloading3 === false &&
      profileImg.trim() !== "" &&
      value !== ""
    );
  };

  const isFormValid2 = () => {
    return (
      vehicleid.trim() !== "" &&
      CarColor.trim() !== "" &&
      input4.trim() !== "" &&
      input4.length === 2 &&
      input3.trim() !== "" &&
      input2.trim() !== "" &&
      input2.length === 3 &&
      input1.trim() !== "" &&
      input1.length === 2 &&
      car_card_photo.trim() !== "" &&
      picture_of_the_insurance_policy.trim() !== "" &&
      imgloading4 === false &&
      imgloading5 === false
    );
  };

  const navigate = useNavigate();
  function Gotonextstep() {
    if (Nationalnum.trim().length < 10) {
      notification.error({
        message: "کد ملی معتبر نیست!",
      });
    } else if (Personalityinf.trim().length < 7) {
      notification.error({
        message: "نام و نام خانوادگی معتبر نیست!",
      });
    } else if (Fathername.trim().length < 3) {
      notification.error({
        message: "نام پدر معتبر نیست!",
      });
    } else {
      setShownextstep(true);
    }
  }
  function handlebackstep() {
    setShownextstep(false);
  }

  function Editprofile() {
    setLoadingprof(true);
    axios
      .put(
        "https://taxi-end.liara.run/driver/v1/update-my-profile",
        {
          full_name: Personalityinf,
          father_name: Fathername,
          address: Address,
          national_code: Nationalnum,
          vehicle: vehicleid,
          plate_code: {
            part1: input4,
            part2: input3,
            part3: input2,
            part4: input1,
          },
          // gender: choosegender || "male",
          vehicle_color: CarColor,
          driving_licence: driving_licenceid,
          national_card: national_card,
          car_card_photo: car_card_photo,
          picture_of_the_insurance_policy: picture_of_the_insurance_policy,
          birth_date: sendDate,
          profile_image: profileImg,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        message.success("اطلاعات با موفقیت ثبت شد");
        getmyprofile();
        setLoadingprof(false);
      })
      .catch((error) => {
        if (error.response) {
          notification.error({
            message: "مشکلی پیش آمده است",
            description: error.response.data.message,
          });
          setLoadingprof(false);
        }
      });
  }
  function getmyprofile() {
    axios
      .get("https://taxi-end.liara.run/driver/v1/get-my-profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setGetmyprof(res.data.data);
        setGetprofloading(false);
        if (getprofloading === true) {
          setLoadingprof(true);
        } else if (getprofloading === false) {
          if (getmyprof) {
            if (res.data.data.status === "profile_not_completed") {
              navigate("/UserandCarinformation");
              setLoadingprof(false);
            } else {
              navigate("/home");
              setLoadingprof(false);
            }
          } else {
            notification.error({
              message: "خطا",
              description: "داده‌های پروفایل کاربر دریافت نشد.",
            });
          }
        }
      })
      .catch((error) => {
        setGetprofloading(true);
      });
  }

  function carChange(e) {
    setCarValue(e.name);
    setVehicleid(e._id);
    setIsShowDropdown(!isShowDropdown);
  }
  const handlechooseimage = () => {
    document.querySelector("#openimage").click();
  };
  const handleImageClick1 = () => {
    document.querySelector("#openfile1").click();
  };
  const handleImageClick2 = () => {
    document.querySelector("#openfile2").click();
  };
  const handleImageClick3 = () => {
    document.querySelector("#openfile3").click();
  };
  const handleImageClick4 = () => {
    document.querySelector("#openfile4").click();
  };
  const handleshowimage = (e) => {
    setImgloading1(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    axios
      .post("https://taxi-end.liara.run/files/v1/driver-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setProfileImg(res.data.data._id);
          setImgloading1(false);
          setDriverimg(true);
          setFile(URL.createObjectURL(e.target.files[0]));
        }
      })
      .catch((error) => {
        setImgloading1(false);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          message.error("خطایی رخ داده است. لطفا دوباره تلاش کنید.");
        }
      });
  };
  const handleImageChange1 = (e) => {
    setImgloading2(true);
    const selectedImage = e.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedImage);
    axios
      .post("https://taxi-end.liara.run/files/v1/driver-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setNational_card(res.data.data._id);
          setImg1(true);
          setImage1(URL.createObjectURL(selectedImage));
          setImgloading2(false);
        }
      })
      .catch((error) => {
        setImgloading2(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          message.error("خطایی رخ داده است. لطفا دوباره تلاش کنید.");
        }
      });
  };

  const handleImageChange2 = (e) => {
    setImgloading3(true);
    const selectedImage = e.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedImage);
    axios
      .post("https://taxi-end.liara.run/files/v1/driver-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setDriving_licenceid(res.data.data._id);
          setImg2(true);
          setImage2(URL.createObjectURL(selectedImage));
          setImgloading3(false);
        }
      })
      .catch((error) => {
        setImgloading3(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          message.error("خطایی رخ داده است. لطفا دوباره تلاش کنید.");
        }
      });
  };
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const handleInput1Change = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      if (e.target.value.length === 2) {
        inputRef2.current.focus();
      }
      setInput4(value);
    }
  };

  const handleInput3Change = (e) => {
    const value = e.target.value;
    if (/^[\u0600-\u06FF\s]*$/.test(value)) {
      setInput3(value);
      if (e.target.value.length === 1) {
        inputRef3.current.focus();
      }
    }
  };

  const handleInput2Change = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInput2(value);
      if (e.target.value.length === 3) {
        inputRef4.current.focus();
      }
    }
  };
  const handleInputchange4 = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInput1(value);
    }
  };
  const handleImageChange3 = (e) => {
    setImgloading4(true);
    const selectedImage = e.target.files[0];
    const formData = new FormData(); // Ensure formData is properly defined locally
    formData.append("file", selectedImage);
    axios
      .post("https://taxi-end.liara.run/files/v1/driver-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCar_card_photo(res.data.data._id);
          setImg3(true);
          setImage3(URL.createObjectURL(selectedImage));
          setImgloading4(false);
        }
      })
      .catch((error) => {
        setImgloading4(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          message.error("خطایی رخ داده است. لطفا دوباره تلاش کنید.");
        }
      });
  };

  const handleImageChange4 = (e) => {
    setImgloading5(true);
    const selectedImage = e.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedImage);
    axios
      .post("https://taxi-end.liara.run/files/v1/driver-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPicture_of_the_insurance_policy(res.data.data._id);
          setImg4(true);
          setImage4(URL.createObjectURL(selectedImage));
          setImgloading5(false);
        }
      })
      .catch((error) => {
        setImgloading5(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error(error.response.data.message);
        } else {
          message.error("خطایی رخ داده است. لطفا دوباره تلاش کنید.");
        }
      });
  };

  function Getdata() {
    axios
      .get("https://taxi-end.liara.run/cars/v1/get-car-list-without-paginate")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setAllcarbrand(res.data.data);
        }
      })
      .catch((e) => {
        setLoading(true);
      });
  }
  // const groupedBycartype = allcarbrand.reduce((all, item) => {
  //   const cartype = item.date;
  //   if (!all[date]) {
  //     all[date] = [];
  //   }
  //   all[date].push(item);
  //   return all;
  // }, {});
  const [value, setValue] = useState("");
  const [openTimePicker, setOpenTimePicker] = useState(false);

  const handleDateChange = (date) => {
    const gregorianDate = new DateObject(date);
    setValue(gregorianDate);
    const formattedDate = gregorianDate
      .convert(gregorian, gregorian_en)
      .format("YYYY-MM-DD");
    setSendDate(formattedDate);
  };

  const handleTimePicker = () => {
    setOpenTimePicker(!openTimePicker);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setNationalnum(value);
    }
    setLoadingprof(false);
  };
  const groupedByDate = allcarbrand.reduce((all, item) => {
    const cartype = item.category.name;
    if (!all[cartype]) {
      all[cartype] = [];
    }
    all[cartype].push(item);
    return all;
  }, {});
  const groupedArray = Object.entries(groupedByDate);
  const [choosegender, setChoosegender] = useState("");
  function handlelogout() {
    setNationalnum("");
    setGetprofloading("");
    setAdress("");
    setCarName("");
    setCarModel("");
    setCarColor("");
    setVehicleid("");
    setDriving_licenceid("");
    setNational_card("");
    setCar_card_photo("");
    setPicture_of_the_insurance_policy("");
    setId("");
    setFathername("");
    setPersonalityinf("");
    setCarValue("");
    setDriverimg("");
    setImage2("");
    setImage3("");
    setImage4("");
    setFile("");
    setImage1("");
    setInput1("");
    setInput2("");
    setInput3("");
    setInput4("");
    setProfileImg("");
    setGetmyprof("");
    setImg1(false);
    setImgloading2(false);
    setImg2(false);
    setImgloading3(false);
    setImg3(false);
    setImg4(false);
    setImgloading5(false);
    setImgloading4(false);
    localStorage.clear();
    navigate("/");
  }

  return (
    <>
      {localStorage.getItem("token") ? (
        <>
          {getprofloading === false &&
          getmyprof.status === "profile_not_completed" ? (
            <>
              <div
                id="userandcarinfdiv"
                className="w-full h-full overflow-auto"
              >
                <>
                  <div className="flex flex-col ">
                    {shownextstep === false && (
                      <>
                        <div
                          className="mr-4 mt-6 flex cursor-pointer"
                          onClick={handlelogout}
                        >
                          <img alt="" src={logoutimg}></img>
                          <p className="text-[16px] font-normal mr-3 text-logoutTC">
                            خروج از حساب
                          </p>
                        </div>
                        <div
                          className="flex flex-col justify-center mt-12 items-center h-full  "
                          onClick={handlechooseimage}
                        >
                          <div className="col-span-1  ">
                            <div className="  cursor-pointer border-applyingbuttoncolor border-[1px] rounded-[50%] w-[121px] h-[121px] ">
                              <div className="h-full flex items-center justify-center">
                                {imgloading1 === false ? (
                                  <>
                                    {driverimg === true && (
                                      <>
                                        <img
                                          alt=""
                                          src={File}
                                          className="w-full h-full rounded-[50%]"
                                        ></img>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <Loadingcom />
                                )}
                              </div>
                            </div>
                            <input
                              onChange={handleshowimage}
                              id="openimage"
                              className="hidden w-[90%]  "
                              type="file"
                              accept="image/*"
                            />
                          </div>
                          <div className="mt-[-36px] mr-[60px] cursor-pointer">
                            <img
                              className="w-[48px] h-[48px]"
                              src={addicon}
                            ></img>
                          </div>
                          <div>
                            <p className="text-[16px]  text-gray-600">
                              عکس پروفایل
                            </p>
                          </div>
                        </div>

                        <div className="mr-4 mt-6">
                          {" "}
                          <p className="text-black font-medium text-[16px]">
                            اطلاعات فردی
                          </p>
                        </div>
                        <div className="space-y-4">
                          <div className=" grid grid-cols-2 w-full gap-4 mt-6">
                            <div className="col-span-1 mr-4">
                              <input
                                onChange={(e) => {
                                  setPersonalityinf(e.target.value);
                                  setLoadingprof(false);
                                }}
                                value={Personalityinf}
                                placeholder="نام و نام خانوادگی"
                                className="w-full text-[14px] placeholder-[rgba(190, 190, 190, 0.2)]  p-2 h-[50px] outline-none border-[1px] border-grayborder bg-transparent rounded-[10px]"
                              />
                            </div>
                            <div className="col-span-1 ml-4">
                              {" "}
                              <input
                                onChange={(e) => {
                                  setFathername(e.target.value);
                                  setLoadingprof(false);
                                }}
                                value={Fathername}
                                placeholder="نام پدر"
                                className="w-full text-[14px] placeholder-[rgba(190, 190, 190, 0.2)] p-2 h-[50px] outline-none border-[1px] border-grayborder bg-transparent rounded-[10px]"
                              />
                            </div>
                          </div>
                          {/* <div className="grid grid-cols-1 ">
                          <div className="col-span-1 mx-4 h-[50px]  ">
                            <div
                              className=" cursor-pointer scrollbar flex flex-row w-full border-[1px]  border-grayborder rounded-[10px] justify-between    "
                              onClick={() => {
                                setShowgender(!showgender);
                              }}
                            >
                              <input
                                value={
                                  choosegender === "male"
                                    ? "مرد"
                                    : choosegender === "female"
                                    ? "زن"
                                    : ""
                                }
                                placeholder=" جنسیت"
                                className="w-full cursor-pointer text-[14px] placeholder-[rgba(190, 190, 190, 0.2)] p-2  h-[50px] outline-none  bg-transparent "
                              />

                              <img
                                alt=""
                                className="ml-4"
                                src={showgender ? flashrotate : flash}
                              ></img>
                            </div>
                            <ul
                              id="scrollbar"
                              className={
                                showgender
                                  ? "   relative z-10 space-y-2 items-center   bg-white overflow-auto  w-full h-[80px]  rounded-[10px] shadow-navboxshadow  "
                                  : "hidden"
                              }
                            >
                              <li
                                className="p-1 px-2 cursor-pointer"
                                onClick={() => {
                                  setChoosegender("male");
                                  setShowgender(false);
                                }}
                              >
                                مرد
                              </li>
                              <li
                                className="p-1 px-2 cursor-pointer"
                                onClick={() => {
                                  setChoosegender("female");
                                  setShowgender(false);
                                }}
                              >
                                زن
                              </li>
                            </ul>
                          </div>
                        </div> */}
                          <div className=" grid grid-cols-1 w-full ">
                            <div className="col-span-1 mx-4 ">
                              {" "}
                              <input
                                onChange={handleInputChange}
                                maxLength={10}
                                value={Nationalnum}
                                placeholder=" شماره ملی"
                                className="w-full text-[14px] placeholder-[rgba(190, 190, 190, 0.2)]  p-2 h-[50px] outline-none border-[1px] border-grayborder bg-transparent rounded-[10px]"
                              />
                            </div>
                          </div>
                          <div className="">
                            {" "}
                            <div className="col-span-1 mx-4">
                              <input
                                onChange={(e) => {
                                  setAdress(e.target.value);
                                  setLoadingprof(false);
                                }}
                                value={Address}
                                placeholder=" آدرس "
                                className="w-full text-[14px] placeholder-[rgba(190, 190, 190, 0.2)]  p-2 h-[50px] outline-none border-[1px] border-grayborder bg-transparent rounded-[10px]"
                              />
                            </div>
                            <div className="col-span-1 mx-4 mt-4">
                              <div className=" cursor-pointer text-gray-400 flex items-center w-full text-[14px] placeholder-[rgba(190, 190, 190, 0.2)]  p-2 h-[50px] outline-none border-[1px] border-grayborder bg-transparent rounded-[10px]">
                                <DatePicker
                                  className="rmdp-mobile"
                                  calendar={persian}
                                  locale={persian_fa}
                                  calendarPosition="bottom-right"
                                  placeholder="تاريخ تولد"
                                  value={value}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                  }}
                                  // format="DDDD-MM-YYYY"
                                  format="YYYY-MM-DD"
                                  onChange={handleDateChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className=" grid grid-cols-2 w-full gap-4  mt-6  ">
                            <div
                              onChange={handleImageChange1}
                              onClick={handleImageClick1}
                              className="col-span-1 bg-transparent  h-[50px] border-[1px] rounded-[10px] border-grayborder mr-4"
                            >
                              <div className="grid grid-cols-2 h-full    w-full">
                                <div className="w-full col-span-1 cursor-pointer flex justify-between    ">
                                  <p className=" flex justify-center items-center pr-2 whitespace-nowrap text-[14px] font-medium text-lightgraytext">
                                    عکس کارت ملی
                                  </p>

                                  <input
                                    id="openfile1"
                                    className=" none hidden    none w-[90%]  "
                                    type="file"
                                    accept="image/*"
                                  />
                                </div>
                                <div className="h-full col-span-1  flex justify-end items-center ml-2">
                                  {" "}
                                  <div className=" cursor-pointer flex justify-center items-center   ">
                                    {img1 === false ? (
                                      <>
                                        {imgloading2 === false ? (
                                          <img
                                            className="w-[24px] h-[24px]"
                                            alt=""
                                            src={uploadimg}
                                          />
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {imgloading2 === false ? (
                                          <div>
                                            <img
                                              alt=""
                                              className="w-[40px] h-[40px] rounded-[5px]"
                                              src={image1}
                                            ></img>
                                          </div>
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              onChange={handleImageChange2}
                              onClick={handleImageClick2}
                              className="col-span-1 bg-transparent  h-[50px] border-[1px] rounded-[10px] border-grayborder ml-4"
                            >
                              <div className="grid grid-cols-2 h-full    w-full">
                                <div className="w-full col-span-1 cursor-pointer flex justify-between    ">
                                  <p className=" flex justify-center items-center pr-2 whitespace-nowrap text-[14px] font-medium text-lightgraytext">
                                    عکس گواهینامه
                                  </p>

                                  <input
                                    id="openfile2"
                                    className=" none hidden    none w-[90%]  "
                                    type="file"
                                    accept="image/*"
                                  />
                                </div>
                                <div className="h-full col-span-1  flex justify-end items-center ml-2">
                                  {" "}
                                  <div className=" cursor-pointer flex justify-center items-center   ">
                                    {img2 === false ? (
                                      <>
                                        {imgloading3 === false ? (
                                          <img
                                            className="w-[24px] h-[24px]"
                                            alt=""
                                            src={uploadimg}
                                          />
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {imgloading3 === false ? (
                                          <div>
                                            <img
                                              alt=""
                                              className="w-[40px] h-[40px] rounded-[5px]"
                                              src={image2}
                                            ></img>
                                          </div>
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mx-4 mt-8">
                          <button
                            onClick={Gotonextstep}
                            disabled={!isFormValid1()}
                            className="  mb-4 rounded-[10px] w-full h-[50px] bg-applyingbuttoncolor outline-none border-none disabled:bg-gray-400 disabled:cursor-not-allowed"
                          >
                            {loadingprof === false ? (
                              <p className="text-[16px] font-medium text-white">
                                بعدی
                              </p>
                            ) : (
                              <Loadingcom />
                            )}
                          </button>
                        </div>
                      </>
                    )}
                    {shownextstep === true ? (
                      <>
                        <div className=" mr-4 mt-8 flex ">
                          <img
                            alt=""
                            src={backicon}
                            className="w-[20px] cursor-pointer"
                            onClick={handlebackstep}
                          ></img>
                          <p className="text-black text-[16px] font-medium pr-2">
                            اطلاعات خودرو
                          </p>
                        </div>
                        <div className="space-y-4 w-full">
                          {" "}
                          <div className=" grid grid-cols-1 w-full  mt-6  ">
                            <div className="col-span-1 mx-4 cursor-pointer">
                              <div
                                className="w-full    rounded-[10px] h-[50px] scrollbar border-[1px]  border-grayborder "
                                onClick={() => {
                                  setIsShowDropdown(!isShowDropdown);
                                  Getdata();
                                }}
                              >
                                <div className=" flex flex-row items-center h-full w-full justify-between   ">
                                  <div>
                                    <div
                                      onChange={(e) => {
                                        setCarValue(e.target.value);
                                        setLoadingprof(false);
                                      }}
                                      value={Carvalue}
                                      className=" cursor-pointer text-[14px] pr-2 w-full   outline-none  bg-transparent "
                                    >
                                      {Carvalue === "" ? (
                                        <p className="text-gray-400">
                                          برند خودرو
                                        </p>
                                      ) : (
                                        <p>{Carvalue}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex justify-end ">
                                    <img
                                      alt=""
                                      className="ml-4"
                                      src={isShowDropdown ? flashrotate : flash}
                                    ></img>
                                  </div>
                                </div>
                                <ul
                                  id="scrollbar"
                                  className={
                                    isShowDropdown
                                      ? "   relative z-10 space-y-2 items-center   bg-white overflow-auto  w-full max-h-[200px]   rounded-[10px] shadow-navboxshadow  "
                                      : "hidden"
                                  }
                                >
                                  {loading === false ? (
                                    <>
                                      {groupedArray.map(
                                        ([cartype, items], cartypeIndex) => (
                                          <div key={cartypeIndex}>
                                            <p className="text-[16px] font-medium  text-center pt-2">
                                              {cartype === "STANDARD"
                                                ? "میان رده"
                                                : cartype === "ECONOMIC"
                                                ? "معمولی"
                                                : cartype}
                                            </p>
                                            {items.map((item) => {
                                              return (
                                                <>
                                                  <li
                                                    className=" py-[4px]      hover:bg-slate-200 pr-4   hover:text-black hover:w-full hover: cursor-pointer"
                                                    onClick={() => {
                                                      carChange(item);
                                                    }}
                                                  >
                                                    <div className="flex p-1">
                                                      {" "}
                                                      <img
                                                        alt=""
                                                        src={`https://taxi-end.liara.run/${item.photo.fileName}`}
                                                        className="w-[28px] h-[28px]"
                                                      ></img>
                                                      <div className="mr-2 flex items-center">
                                                        <p className="flex items-center text-[12px]   ">
                                                          {item.name}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </>
                                              );
                                            })}
                                          </div>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <div className="flex h-[40px]  justify-center items-center">
                                      <Loadingcom />
                                    </div>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className=" grid grid-cols-1 w-full  mt-6">
                            <div className="col-span-1 mx-4">
                              {" "}
                              <input
                                onChange={(e) => {
                                  setCarColor(e.target.value);
                                  setLoadingprof(false);
                                }}
                                value={CarColor}
                                placeholder=" رنگ"
                                className="w-full text-[14px] placeholder-[rgba(190, 190, 190, 0.2)] p-2 h-[50px] outline-none border-[1px] border-grayborder bg-transparent rounded-[10px]"
                              />
                            </div>
                          </div>
                          <div className="mr-4">
                            <p className="text-graytextforexit">
                              {" "}
                              پلاک ماشین :
                            </p>
                          </div>
                          <div className="  col-span-1 flex mx-4 gap-4   ">
                            <div className=" border-[1px] border-grayborder rounded-[10px] w-[15%] h-[48px] flex-col text-center">
                              <p className="text-[8px] font-medium text-graytextforexit pt-1 ">
                                ایران
                              </p>
                              <input
                                maxLength={2}
                                ref={inputRef4}
                                className="bg-transparent w-full outline-none flex justify-center text-center"
                                value={input1}
                                onChange={(e) => handleInputchange4(e)}
                              ></input>
                            </div>
                            <input
                              maxLength={3}
                              ref={inputRef3}
                              className="bg-transparent border-[1px] border-grayborder rounded-[10px] w-[40%] outline-none text-center "
                              value={input2}
                              onChange={(e) => handleInput2Change(e)}
                            ></input>
                            <input
                              maxLength={1}
                              ref={inputRef2}
                              className="bg-transparent border-[1px] border-grayborder rounded-[10px] w-[15%] outline-none flex justify-center text-center"
                              value={input3}
                              onChange={(e) => handleInput3Change(e)}
                            ></input>
                            <input
                              maxLength={2}
                              className="bg-transparent border-[1px] border-grayborder rounded-[10px] w-[30%] outline-none flex justify-center text-center"
                              value={input4}
                              onChange={(e) => handleInput1Change(e)}
                            ></input>
                          </div>
                          <div className=" grid grid-cols-2 w-full gap-4  mt-6  ">
                            <div
                              onChange={handleImageChange3}
                              onClick={handleImageClick3}
                              className="col-span-1 bg-transparent  h-[50px] border-[1px] rounded-[10px] border-grayborder mr-4"
                            >
                              <div className="grid grid-cols-2 h-full    w-full">
                                <div className="w-full col-span-1 cursor-pointer flex justify-between    ">
                                  <p className=" flex justify-center items-center pr-2 whitespace-nowrap text-[14px] font-medium text-gray-400">
                                    عکس کارت ماشین
                                  </p>

                                  <input
                                    id="openfile3"
                                    className=" none hidden    none w-[90%]  "
                                    type="file"
                                    accept="image/*"
                                  />
                                </div>
                                <div className="h-full col-span-1  flex justify-end items-center ml-2">
                                  {" "}
                                  <div className=" cursor-pointer flex justify-center items-center   ">
                                    {img3 === false ? (
                                      <>
                                        {imgloading4 === false ? (
                                          <img
                                            className="w-[24px] h-[24px]"
                                            alt=""
                                            src={uploadimg}
                                          />
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {imgloading4 === false ? (
                                          <div>
                                            <img
                                              alt=""
                                              className="w-[35px] h-[35px] rounded-[5px]"
                                              src={image3}
                                            ></img>
                                          </div>
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              onChange={handleImageChange4}
                              onClick={handleImageClick4}
                              className="col-span-1 bg-transparent  h-[50px] border-[1px] rounded-[10px] border-grayborder ml-4"
                            >
                              <div className="grid grid-cols-2 h-full    w-full">
                                <div className="w-full col-span-1 cursor-pointer flex justify-between    ">
                                  <p className=" flex justify-center items-center pr-2 whitespace-nowrap text-[14px] font-medium text-gray-400">
                                    عکس بیمه نامه
                                  </p>

                                  <input
                                    id="openfile4"
                                    className=" none hidden    none w-[90%]  "
                                    type="file"
                                    accept="image/*"
                                  />
                                </div>
                                <div className="h-full col-span-1  flex justify-end items-center ml-2">
                                  {" "}
                                  <div className=" cursor-pointer flex justify-center items-center   ">
                                    {img4 === false ? (
                                      <>
                                        {imgloading5 === false ? (
                                          <img
                                            className="w-[24px] h-[24px]"
                                            alt=""
                                            src={uploadimg}
                                          />
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {imgloading5 === false ? (
                                          <div>
                                            <img
                                              alt=""
                                              className="w-[35px] h-[35px] rounded-[5px]"
                                              src={image4}
                                            ></img>
                                          </div>
                                        ) : (
                                          <Loadingcom />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mx-4 mt-10">
                          <button
                            onClick={Editprofile}
                            disabled={!isFormValid2()}
                            className="  mb-4 rounded-[10px] w-full h-[50px] bg-applyingbuttoncolor outline-none border-none disabled:bg-gray-400 disabled:cursor-not-allowed"
                          >
                            <div className="flex justify-center items-center">
                              {loadingprof === false ? (
                                <p className="text-[16px] font-medium text-white">
                                  ثبت نام
                                </p>
                              ) : (
                                <Loadingcom />
                              )}
                            </div>
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              </div>
            </>
          ) : (
            <Navigate to="/home" />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
export default UserandCarinf;
