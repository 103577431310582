import React, { useContext, useEffect, useState } from "react";
import beginningimg from "../image/beginning.svg";
import backicon from "../image/backicon.svg";
import desimg from "../image/destination.svg";
import Modal from "react-modal";
import successaplyingimg from "../image/succesaplying.svg";
import { UseContext } from "../context";
import { useNavigate, useLocation } from "react-router-dom";
import "@neshan-maps-platform/mapbox-gl-react/dist/style.css";
import nmp_mapboxgl from "@neshan-maps-platform/mapbox-gl";
import { MapComponent, MapTypes } from "@neshan-maps-platform/mapbox-gl-react";
import "@neshan-maps-platform/mapbox-gl-react/dist/style.css";
import "./Scroll.css";
import TimeOnly from "./allcomponent/time";
const Everytravelcard = () => {
  const [changecolorA, setChangecolorA] = useState(false);
  const [showsuccessmodal, setShowsuccessmodal] = useState(false);

  const {
    setShoweverytravelcard,
    setShowhis,
    setShowhistrinprogress,
    setShowtrinprogress,
  } = useContext(UseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const handleback = () => {
    navigate("/home");
  };
  const handleshowhiscom = () => {
    setShoweverytravelcard(false);
    navigate("/history");
  };
  const closeshowsuccessmodal = () => {
    setShowsuccessmodal(false);
  };
  const successaplying = () => {
    setChangecolorA(true);
    setShowsuccessmodal(true);
    setShowtrinprogress(true);
    setShowhis(false);
    setShowhistrinprogress(true);
  };
  const showsuccessmodalstyle = {
    content: {
      borderRadius: "8px",
    },
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  if (window.innerWidth < 768) {
    showsuccessmodalstyle.content.width = "228px";
    showsuccessmodalstyle.content.height = "280px";
    showsuccessmodalstyle.content.margin = "auto";
    showsuccessmodalstyle.content.right = "40px";
    showsuccessmodalstyle.content.overflow = "hidden";
  } else {
    showsuccessmodalstyle.content.width = "228px";
    showsuccessmodalstyle.content.height = "280px";
    showsuccessmodalstyle.content.overflow = "hidden";
    showsuccessmodalstyle.content.top = "50%";
    showsuccessmodalstyle.content.right = "50%";
    showsuccessmodalstyle.content.transform = "translate(50%, -50%)";
  }
  // useEffect(() => {
  //   axios.get(
  //     "https://api.neshan.org/v4/static?key=YOUR_API_KEY&type=neshan&width=500&height=500&zoom=12&center=32.657307%2C51.677579&markerToken=101139.nRmybq5",
  //     {}
  //   );
  // }, []);

  //create path

  useEffect(() => {
    if (data !== null) {
      const neshanMap = new nmp_mapboxgl.Map({
        mapType: nmp_mapboxgl.Map.mapTypes.neshanVector,
        container: "map",
        zoom: 14,
        pitch: 0,
        center: [51.379671542050204, 35.74875391151226],
        minZoom: 2,
        maxZoom: 21,
        trackResize: true,
        mapKey: "web.eac958c29cec49699156fa02733fb8b3",
        poi: true,
        traffic: false,
        isZoomControlsEnabled: true,
        setZoomGesturesEnabled: true,
        mapTypeControllerOptions: {
          show: false,
        },
      });

      neshanMap.addControl(
        new nmp_mapboxgl.NavigationControl(),
        "bottom-right"
      );

      new nmp_mapboxgl.Marker()
        .setLngLat([51.379671542050204, 35.74875391151226])
        .addTo(neshanMap);
    }
  }, [data]);
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {data !== null ? (
            <>
              {" "}
              <Modal
                isOpen={showsuccessmodal}
                onRequestClose={closeshowsuccessmodal}
                style={showsuccessmodalstyle}
              >
                <div className=" flex justify-center items-center  relative bottom-2  ">
                  <img alt="" src={successaplyingimg}></img>
                </div>
                <p className="text-[22px] font-medium text-center">
                  سفر تایید شد
                </p>
                <p className="text-[14px] text-graytext font-normal text-center relative top-3">
                  سفری که قبول کردید در قسمت سفر های در جریان شما نمایش داده می
                  شود.
                </p>
                <div
                  className="cursor-pointer z-30 "
                  onClick={handleshowhiscom}
                >
                  {" "}
                  <p className="text-[black] font-medium text-center whitespace-nowrap relative top-10 text-[14px]">
                    به صفحه مربوط هدایت شوید{" "}
                  </p>
                </div>
              </Modal>
              <div className="w-full h-full overflow-auto  flex-row ">
                <div className="row-span-1 mt-12 mr-4 ">
                  <img
                    alt=""
                    onClick={handleback}
                    className="cursor-pointer"
                    src={backicon}
                  ></img>
                </div>
                <div className="row-span-1 mt-4 mx-4  ">
                  <div className="w-full h-[250px] ">
                    <MapComponent
                      id="map"
                      options={{
                        mapKey: "web.48d4c2ad480f4459a65222c590a47fe3",
                        mapType: MapTypes.neshanVector,
                        mapTypeControllerOptions: {
                          show: false,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row-span-1 mt-7">
                  <div className="grid  grid-cols-2">
                    <div className=" col-span-1   mr-4 text-[14px]">
                      <p>
                        <TimeOnly time={data.travel_date} />
                      </p>
                    </div>
                    <div className=" col-span-1    flex justify-end ml-4 text-[14px]">
                      <p> {data.travel_hour_time} </p>
                    </div>
                  </div>
                </div>
                <div className="row-span-1 mt-4">
                  <div className="border-[1px] mr-4 ml-4 h-[1px] border-[rgba(0, 0, 0, 0.2)] "></div>
                </div>
                <div className="flex-row space-y-4">
                  <div className="row-span-1 flex gap-2 w-full text-[14px]">
                    <img
                      alt=""
                      src={desimg}
                      className="h-[18px] w-[12px]  mt-4  mr-4"
                    ></img>
                    <p className="mt-3"> {data.destination_city.name}</p>
                  </div>
                  <div className="row-span-1  flex w-full">
                    <img
                      alt=""
                      src={beginningimg}
                      className="h-[18px] w-[12px]  mt-4  mr-4"
                    ></img>

                    <div className="pt-1 flex text-[14px]">
                      <p className="relative top-2 whitespace-nowrap pr-2">
                        {data.origin_city.name} ,
                      </p>
                      <p className="relative top-2 whitespace-nowrap pr-1">
                        {data.address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row-span-1 mt-4">
                  {" "}
                  <div className="border-[1px] mr-4 ml-4 h-[1px] border-[rgba(0, 0, 0, 0.2)] "></div>
                </div>
                <div className="row-span-1 grid grid-cols-2 text-black text-[16px] font-medium">
                  <div className="col-span-1 flex mt-3 ">
                    <p className="mr-4  ">{data.passenger_count} </p>
                    <p className="mr-1  ">نفر </p>
                  </div>
                  <div className="col-span-1 flex  mt-4 justify-end ml-4 gap-1 ">
                    <p className=" flex justify-end text-[16px] font-medium ">
                      {data.price}
                    </p>
                    <p>تومان</p>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center">
                <button
                  className="w-[100%] mx-4  h-[40px] rounded-[10px] lg:mb-10 mb-8  "
                  style={{
                    backgroundColor:
                      changecolorA === true
                        ? "rgba(67, 160, 72, 1)"
                        : "rgba(16, 14, 52, 1) ",
                  }}
                >
                  <p
                    className="text-whitecolor text-[16px]"
                    onClick={successaplying}
                  >
                    {" "}
                    قبول درخواست
                  </p>
                </button>
              </div>
            </>
          ) : (
            navigate("/home")
          )}
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Everytravelcard;
