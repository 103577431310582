import React, { useEffect, useState } from "react";
import backicon from "../image/backicon.svg";
import sendicon from "../image/sendicon.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UseContext } from "../context";

import "./Scroll.css";
import Loadingcom from "./allcomponent/Loading";
const Messagecom = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const Messages = [
    " تماس می گیرم.",
    "در مبدا منتظرتونم.",
    "چند دقیقه دیگر می رسم.",
    "لطفا چند دقیقه در مبدا منتظر بمانید",
  ];

  const [allmessage, setAllmesssage] = useState([]);
  const { setShowmessagecom, getmyprof, getprofloading } =
    useContext(UseContext);
  const handleclosemessagecom = () => {
    navigate("/report");
    setShowmessagecom(false);
  };

  const [justdate, setjustdate] = useState("");
  const [hour, setHour] = useState("");
  useEffect(() => {
    let date = new Date();
    setHour(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);
    let dayIndex = date.getDay();
    switch (dayIndex) {
      case 0:
        setjustdate("یکشنبه");
        break;
      case 1:
        setjustdate("دوشنبه");
        break;
      case 2:
        setjustdate("سه‌شنبه");
        break;
      case 3:
        setjustdate("چهارشنبه");
        break;
      case 4:
        setjustdate("پنج‌شنبه");
        break;
      case 5:
        setjustdate("جمعه");
        break;
      case 6:
        setjustdate("شنبه");
        break;
      default:
        setjustdate("خطا در تنظیم تاریخ");
    }
  }, []);
  const handlechoosemessage = (message) => {
    setMessage(message);
    setClickCount(clickCount + 1);
  };

  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {getprofloading === false ? (
            <>
              {getmyprof.status === "profile_not_completed" ? (
                <Navigate to="/UserandCarinformation" />
              ) : (
                <>
                  {getmyprof.status === "pending" ? (
                    <div className="w-full flex flex-col h-full  overflow-auto mb-[100px] lg:mb-[88px]">
                      <div className=" h-full w-full flex flex-col   items-center justify-center ">
                        <>
                          <div
                            id="traveldiv"
                            className=" flex  items-center justify-center  "
                          >
                            <p className="text-[20px] text-center  text-[black] font-bold">
                              حساب کاربری شما هنوز تایید نشده است!
                            </p>
                          </div>
                          <div className="mt-4">
                            <Loadingcom />
                          </div>
                        </>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="w-full h-full lg:flex lg:flex-col lg:justify-between  ">
                        <div
                          id="messagediv"
                          className="w-full overflow-y-auto    "
                        >
                          <div
                            className="pr-7 pt-12 cursor-pointer"
                            onClick={handleclosemessagecom}
                          >
                            <img alt="" src={backicon}></img>
                          </div>
                          <p className="text-[16px] text-black font-medium pt-5 pr-7">
                            محمد باقر جوادی
                          </p>

                          <div className="mt-8 px-4 w-full">
                            <p className="text-[16px]  font-medium text-lightgraytext text-center ">
                              اینجا می توانید به مسافر پیام بفرستید یا پیام های
                              مسافر را دریافت کنید.
                            </p>
                          </div>
                          <div className="flex w-full justify-center">
                            <div className="flex items-center  justify-center mt-4 h-[20px] bg-grayinput w-fit px-4 rounded-[5px] ">
                              <p className="text-[12px] text-black">
                                {justdate}
                              </p>
                            </div>
                          </div>

                          <>
                            <div className="mt-4 space-y-2  mx-4 text-[12px]">
                              {allmessage.map((message) => {
                                return (
                                  <>
                                    <div className="p-2   text-white max-w-[300px] border-2 rounded-tl-2xl rounded-br-2xl rounded-bl-2xl bg-graytext">
                                      <p className=" break-words">
                                        {message.message}
                                      </p>
                                    </div>
                                    <p className=" font-semibold text-graytextforexit">
                                      {" "}
                                      {message.hour}
                                    </p>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        </div>

                        <div className="  grid grid-cols-1 w-full mb-12  fixed lg:sticky bottom-3   ">
                          <div
                            id="Messagediv"
                            className="flex gap-2 mx-4 mb-6 overflow-x-auto h-full lg:overflow-x-auto "
                          >
                            {Messages.map((item) => {
                              return (
                                <div
                                  className="h-[30px] whitespace-nowrap  rounded-[10px] bg-messagecolor px-2 cursor-pointer "
                                  onClick={() => {
                                    handlechoosemessage(item);
                                  }}
                                >
                                  <p className="text-[12px] font-normal flex items-center h-full">
                                    {" "}
                                    {item}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          <div className="    flex justify-start col-span-1 ">
                            <div className=" cursor-pointer ">
                              <div
                                onClick={
                                  message !== ""
                                    ? () =>
                                        setAllmesssage([
                                          ...allmessage,
                                          { message: message, hour: hour },
                                        ])
                                    : null
                                }
                                className=" mr-2    w-[40px] h-[40px] bg-graycirclecolor rounded-[50%]  flex justify-center items-center"
                              >
                                <img alt="" src={sendicon}></img>
                              </div>{" "}
                            </div>
                            <div className="w-full mx-4">
                              <div>
                                <input
                                  type="text"
                                  value={message}
                                  onChange={(e) => {
                                    setMessage(e.target.value);
                                  }}
                                  placeholder="پیام خود را بنویسید..."
                                  className="bg-graycirclecolor h-[40px] text-[12px] rounded-[10px] w-full  outline-none border-none p-4 placeholder-colorplaceholder  font-normal "
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className=" h-full w-full flex flex-col   items-center justify-center ">
              <>
                <div
                  id="traveldiv"
                  className=" flex  items-center justify-center  "
                >
                  <p className="text-[20px] text-center  text-[black] font-bold">
                    در انتظار دریافت اطلاعات ...
                  </p>
                </div>
              </>
            </div>
          )}
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Messagecom;
