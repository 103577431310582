import React from "react";
import locationimg from "../image/placeholder 1.svg";
import { useNavigate } from "react-router-dom";
const Search = () => {
  const navigate = useNavigate();
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {" "}
          <div className="w-full h-full overflow-hidden relative flex flex-col  ">
            <>
              <div className=" row-span-1 w-[100%]  flex  h-full    ">
                <div className="lg:w-[100%] w-[100%] mr-[16px] ml-[16px] h-[40px] rounded-[10px] flex items-center justify-center bg-grayinput gap-4 lg:mt-12  mt-12   ">
                  <div>
                    <img alt="" src={locationimg} className="mr-4"></img>
                  </div>
                  <input
                    type="text"
                    className="w-full bg-transparent outline-none h-full p-[2px]"
                    placeholder="شهر خود را انتخاب کنید..."
                  />
                </div>
              </div>
              <div className=" row-span-1 text-center h-full ">
                <p className="text-graytext   ">
                  تا زمانی که آمادگی خودت رو اعلام نکردی سفری برات نمایش داده
                  نمیشه.
                </p>
              </div>
            </>
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Search;
