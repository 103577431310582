import React from "react";
const TimeOnly = ({ time }) => {
  const jalaliMoment = require("jalali-moment");
  const jalaliDate = jalaliMoment(time);
  const jalaliYear = jalaliDate.format("jYYYY");
  const jalaliMonth = jalaliDate.format("jM");
  const jalaliDay = jalaliDate.format("jD");
  const timeOnlyStr = `${jalaliYear}/${jalaliMonth}/${jalaliDay} `;
  return <div> {timeOnlyStr}</div>;
};
export default TimeOnly;
