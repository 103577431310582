import React, { useContext, useEffect, useState } from "react";
import begginingimg from "../image/beginning.svg";
import flashrotate from "../image/flashrotate.svg";
import flash from "../image/flash.svg";
import destinationimg from "../image/destination.svg";
import { UseContext } from "../context";
import loacationimg from "../image/placeholder 1.svg";
import axios from "axios";
import Loadingcom from "./allcomponent/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import "./Scroll.css";
import firebase from "firebase/compat/app";
import "firebase/database";
import TimeOnly from "./allcomponent/time";
import humanface from "../image/human1.svg";
import humanbody from "../image/human2.svg";
const TravelCard = () => {
  const {
    setShowhomecom,
    showhomecom,
    allcity,
    setAllcity,
    loading,
    setLoading,
    showexit,
    setShownav,
    getmyprof,
    getprofloading,
    setGetmyprof,
  } = useContext(UseContext);
  const [travelloading, setTravelloading] = useState(true);
  const [cityValue, setCityValue] = useState("");
  const navigate = useNavigate();

  // const firebaseConfig = {
  //   apiKey: "AIzaSyBrdp9Kmf2JIdB35mqCDLWYY58rb-3a8YI",
  //   authDomain: "travel-f0c35.firebaseapp.com",
  //   projectId: "travel-f0c35",
  //   storageBucket: "travel-f0c35.appspot.com",
  //   messagingSenderId: "505809502099",
  //   appId: "1:505809502099:web:16940417a34e02c745b266",
  //   measurementId: "G-9CWTG47NL5",
  // };
  // firebase.initializeApp(firebaseConfig);
  // const database = firebase.database();

  // useEffect(() => {
  //   const tripref = database.ref("trips");
  //   tripref.on("child-added", (data) => {
  //     const newTrip = data.val();
  //   });
  // }, []);

  const [alltravelfordriver, setAlltravelfordriver] = useState([]);
  const [notfoundtravel, setNotfoundtravel] = useState(false);
  function Getdata() {
    axios
      .get("https://taxi-end.liara.run/states/v1/get-city-list")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setAllcity(res.data.data.docs);
        } else {
          setLoading(true);
        }
      })
      .catch((e) => {
        setLoading(true);
      });
  }
  function GetProfile() {
    axios
      .get("https://taxi-end.liara.run/driver/v1/get-my-profile")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setGetmyprof(res.data.data.docs);
        } else {
          setLoading(true);
        }
      })
      .catch((e) => {
        setLoading(true);
      });
  }
  useEffect(() => {
    GetProfile();
  }, []);

  useEffect(() => {
    axios
      .get("https://taxi-end.liara.run/travel/v1/get-travels-driver-accept", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAlltravelfordriver(res.data.data.docs);
          setTravelloading(false);
        } else {
          setTravelloading(true);
        }
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          setNotfoundtravel(true);
        }
        setTravelloading(true);
      });
  }, [alltravelfordriver, travelloading, setTravelloading]);

  function cityChange(e) {
    setCityValue(e);
    setIsShowDropdown(!isShowDropdown);
  }

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const showeverytravelc = (item) => {
    navigate("/home/travelinformation", { state: item });
    setShowhomecom(false);
  };
  console.log(notfoundtravel);
  function handleshowEditprof() {
    navigate("/Edit");
    setShownav(false);
  }
  return (
    <>
      {localStorage.getItem("token") !== null ? (
        <>
          {getprofloading === false ? (
            <>
              {getmyprof.status === "profile_not_completed" ? (
                <Navigate to="/UserandCarinformation" />
              ) : (
                <>
                  {getmyprof.status === "pending" ? (
                    <div className="w-full flex flex-col h-full  overflow-auto mb-[100px] lg:mb-[88px]">
                      {getmyprof.profile_image.fileName === "" ? (
                        <>
                          <div
                            className="bg-grayinput border-[2px] border-black m-4  rounded-[50%] w-[40px] h-[40px] cursor-pointer  "
                            onClick={handleshowEditprof}
                          >
                            <div className="w-full flex justify-center mt-1">
                              <img alt="" className="" src={humanface}></img>
                            </div>
                            <div className="w-full flex justify-center mt-1">
                              <img alt="" src={humanbody}></img>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="border-[1px] border-gray-500 rounded-[50%] mr-2 mt-2 w-[70px] h-[70px] cursor-pointer">
                          <img
                            onClick={handleshowEditprof}
                            className="rounded-[50%] w-[70px] h-[70px] cursor-pointer"
                            src={`https://taxi-end.liara.run/${getmyprof.profile_image.fileName}`}
                          ></img>
                        </div>
                      )}
                      <div className=" h-full w-full flex flex-col   items-center justify-center ">
                        <>
                          <div
                            id="traveldiv"
                            className=" flex  items-center justify-center  "
                          >
                            <p className="text-[20px] text-center  text-[black] font-bold">
                              حساب کاربری شما هنوز تایید نشده است!
                            </p>
                          </div>
                          <div className="mt-4">
                            <Loadingcom />
                          </div>
                        </>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="w-[100%]  flex  justify-center items-center  ">
                        <div className="w-full flex  mx-4 gap-4  mt-6">
                          <div className="w-[10%]">
                            {getmyprof.profile_image.fileName === "" ? (
                              <>
                                <div
                                  className="bg-grayinput border-[2px] border-black m-4  rounded-[50%] w-[40px] h-[40px] cursor-pointer  "
                                  onClick={handleshowEditprof}
                                >
                                  <div className="w-full flex justify-center mt-1">
                                    <img
                                      alt=""
                                      className=""
                                      src={humanface}
                                    ></img>
                                  </div>
                                  <div className="w-full flex justify-center mt-1">
                                    <img alt="" src={humanbody}></img>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="border-[1px] border-gray-500 rounded-[50%]  w-[40px] h-[40px] cursor-pointer">
                                <img
                                  onClick={handleshowEditprof}
                                  className="rounded-[50%] w-[40px] h-[40px] cursor-pointer"
                                  src={`https://taxi-end.liara.run/${getmyprof.profile_image.fileName}`}
                                ></img>
                              </div>
                            )}
                          </div>
                          <div className=" cursor-pointer w-[90%]   bg-grayinput rounded-[10px] h-[40px] scrollbar ">
                            <div className=" h-full flex flex-col">
                              <div
                                className=" flex items-center h-full w-full col-span-1  justify-between"
                                onClick={
                                  showexit === false
                                    ? () => {
                                        setIsShowDropdown(!isShowDropdown);
                                        Getdata();
                                      }
                                    : null
                                }
                              >
                                <img
                                  alt=""
                                  src={loacationimg}
                                  className="mr-4"
                                />
                                <input
                                  onChange={(e) => setCityValue(e.target.value)}
                                  className="cursor-pointer w-full pr-4  border-none outline-none bg-transparent"
                                  placeholder="شهر خود را انتخاب کنید ..."
                                  value={cityValue}
                                />

                                <img
                                  alt=""
                                  className="  flex items-center pl-4 justify-end col-span-1  "
                                  src={isShowDropdown ? flashrotate : flash}
                                ></img>
                              </div>
                            </div>

                            <ul
                              id="scrollbar"
                              className={
                                isShowDropdown
                                  ? ` relative z-10 space-y-2 items-center max-h-[200px]    bg-white overflow-auto  w-full  rounded-[10px] shadow-navboxshadow  ${
                                      loading === true ? "h-[200px] " : "h-fit"
                                    }`
                                  : "hidden"
                              }
                            >
                              {loading === false ? (
                                <>
                                  {" "}
                                  {allcity
                                    .filter((city) =>
                                      `${city.name}`
                                        .toLowerCase()
                                        .includes(cityValue.toLowerCase())
                                    )
                                    .map((item) => {
                                      return (
                                        <>
                                          <li
                                            className=" py-[8px]     hover:bg-slate-200 pr-4  hover:text-black hover:w-full hover: cursor-pointer"
                                            onClick={() => {
                                              cityChange(item.name);
                                            }}
                                          >
                                            <div className="h-full flex items-center ">
                                              <p className="  "> {item.name}</p>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}{" "}
                                </>
                              ) : (
                                loading === true && (
                                  <>
                                    <div className="flex h-full justify-center items-center">
                                      <Loadingcom />
                                    </div>
                                  </>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        id="traveldiv"
                        className={`w-full h-full lg:mb-[88px] mb-[100px] ${
                          travelloading === false ? " overflow-auto" : ""
                        }`}
                      >
                        <div className="w-full h-full  relative top-6 space-y-4 ">
                          {showhomecom === true && travelloading === false ? (
                            alltravelfordriver.filter((city) =>
                              `${city.destination_city.name}`
                                .toLowerCase()
                                .includes(cityValue.toLowerCase())
                            ).length > 0 ? (
                              alltravelfordriver
                                .filter((city) =>
                                  `${city.destination_city.name}`
                                    .toLowerCase()
                                    .includes(cityValue.toLowerCase())
                                )
                                .map((item) => {
                                  return (
                                    <div
                                      className={`lg:flex   ml-4 mr-4 ${
                                        showexit === false
                                          ? "cursor-pointer"
                                          : ""
                                      } `}
                                      onClick={
                                        showexit === false
                                          ? () => showeverytravelc(item)
                                          : null
                                      }
                                    >
                                      <div className="grid grid-rows-2 grid-flow-col w-full lg:w-[100%] h-[74px] bg-white rounded-[10px] shadow-cardtravelshadow1 shadow-cardtravelshadow2 shadow-cardtravelshadow3 ">
                                        <div className="grid grid-cols-2  row-span-1   ">
                                          <div className="flex  col-space-1 text-[14px] lg:text-[12px] lp:text-[14px]">
                                            <img
                                              alt=""
                                              src={begginingimg}
                                              className="w-[12px] h-[20px] mr-4 mt-2 "
                                            />
                                            <p className="relative top-2 whitespace-nowrap pr-4">
                                              {item.origin_city.name} ,
                                            </p>
                                            <p className="relative top-2 whitespace-nowrap pr-1">
                                              {item.address
                                                .split(" ")
                                                .slice(0, 3)
                                                .join(" ")}
                                              {item.address.split(" ").length >
                                              3
                                                ? "..."
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="flex justify-end mt-2 ml-4 text-[14px] font-[400]">
                                            <p className="col-space-1 whitespace-nowrap">
                                              <TimeOnly
                                                time={item.travel_date}
                                              />
                                            </p>
                                            <p className="col-space-1 whitespace-nowrap">
                                              | {item.travel_hour_time}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2 row-span-1">
                                          <div className="flex col-space-1 text-[14px]">
                                            <div className="mr-4 mt-2">
                                              <img
                                                alt=""
                                                src={destinationimg}
                                                className="w-[12px] h-[20px] "
                                              />
                                            </div>
                                            <p className="relative top-2 whitespace-nowrap pr-4">
                                              {item.destination_city.name}
                                            </p>
                                          </div>
                                          <div className="col-span-1 flex justify-end mt-2 ml-4 text-[14px] font-[400] gap-2">
                                            <p className="text-pricetxcolor whitespace-nowrap">
                                              {item.price}
                                            </p>
                                            <p>تومان</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                            ) : (
                              <div className="flex h-full justify-center items-center">
                                <Loadingcom />
                              </div>
                            )
                          ) : (
                            <div className=" h-full justify-center items-center">
                              <div className=" h-full w-full flex flex-col   items-center justify-center ">
                                <>
                                  <div
                                    id="reportdiv"
                                    className=" flex  items-center justify-center  "
                                  >
                                    {notfoundtravel === true ? (
                                      <p className="text-[20px] text-center  text-[black] font-bold">
                                        سفری یافت نشد!
                                      </p>
                                    ) : (
                                      <>
                                        {" "}
                                        <div className="mt-4">
                                          <Loadingcom />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className=" h-full w-full flex flex-col   items-center justify-center ">
              <>
                <div
                  id="traveldiv"
                  className=" flex  items-center justify-center  "
                >
                  <p className="text-[20px] text-center  text-[black] font-bold">
                    در انتظار دریافت اطلاعات ...
                  </p>
                </div>
              </>
            </div>
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
export default TravelCard;
